/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Suspense} from "react";
import {  Route, Routes } from 'react-router-dom';
import { useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import AppTopbar from "../Components/AppTopbar";
import { AppFooter } from "../Components/AppFooter";
import { setAuthToken } from "../Service/api";
import { AppMenu } from "../Components/AppMenu";
import Breadcrumbs from "../Components/AppBreadcrumb";
import Notification from "../Components/Notification";
import { componentRoutes } from "../Route/ComponentRoutes";
import Loading from "../Components/Loading";
import logo from "../assets/images/Reveknew_Logo_All_White.png";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";
import "primereact/resources/primereact.min.css";
import "../Styles/theme/theme.min.css";
import "../App.css";
import "../Styles/global.scss"
import "../Styles/font/Roboto/Roboto-Bold.ttf"
import "../Styles/font/Roboto/Roboto-Medium.ttf"
import { useKeycloak } from "@react-keycloak/web";
import IdleLogout from "../Pages/Authentication/IdleLogout";

const App = (props) => {
  const [state, setState] = useState({
    layoutMode: "static",
    overlayMenuActive: false,
    staticMenuDesktopInactive: false,
    staticMenuMobileActive: false,
    topbarMenuActive: false,
    activeTopbarItem: null,
    darkMenu: true,
    menuActive: false,
    profileMode: "inline",
    themeColor: "pink-accent",
    grouped: true,
    inlineProfileActive: false,
    configDialogActive: false,
    accessTokenExists: false,
    businessAccount: {},
  });

  const { t } = useTranslation();

  const { keycloak } = useKeycloak();
  const [accessTokenExists, setAccessTokenExists] = useState(false);

  //Redux State from Store
  const defMembership = useSelector((state) => state.defMembership.defMembership);
  const profile = useSelector((state) => state.profile.profile);
  const business = useSelector((state) => state.businesses.business)

  useEffect(() => {
    if (keycloak.authenticated) {
      setAuthToken(keycloak.token);
      setAccessTokenExists(true);
    } else {
      setAccessTokenExists(false);
    }
  }, [keycloak.authenticated, keycloak.token]);
  

  useEffect(() => {
    if (business) {
      setState((state) => { return { ...state, businessAccount: business } })
    }
  }, [business])

  let menuClick, inlineProfileClick, topbarItemClick, configClick;

  const onMenuClick = (event) => {
    menuClick = true;

    if (state.inlineProfileActive && !inlineProfileClick && isSlim()) {
      setState((prev) => {
        return { ...prev, inlineProfileActive: false };
      });
    }
    inlineProfileClick = false;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (state.layoutMode === "overlay") {
      if (isDesktop())
        setState((prev) => {
          return { ...prev, overlayMenuActive: !state.overlayMenuActive };
        });
      else
        setState((prev) => {
          return {
            ...prev,
            staticMenuMobileActive: !state.staticMenuMobileActive,
          };
        });
    } else {
      if (isDesktop())
        setState((prev) => {
          return {
            ...prev,
            staticMenuDesktopInactive: !state.staticMenuDesktopInactive,
          };
        });
      else
        setState((prev) => {
          return {
            ...prev,
            staticMenuMobileActive: !state.staticMenuMobileActive,
          };
        });
    }

    event.preventDefault();
  };

  const onTopbarMenuButtonClick = (event) => {
    topbarItemClick = true;
    setState((prev) => {
      return { ...prev, topbarMenuActive: !state.topbarMenuActive };
    });
    hideOverlayMenu();
    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;

    if (state.activeTopbarItem === event.item)
      setState((prev) => {
        return { ...prev, activeTopbarItem: null };
      });
    else
      setState((prev) => {
        return { ...prev, activeTopbarItem: event.item };
      });

    event.originalEvent.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setState((prev) => {
        return { ...prev, menuActive: false };
      });
    }
  };
  const onRootMenuItemClick = (event) => {
    setState((prev) => {
      return { ...prev, menuActive: !state.menuActive };
    });
    event.originalEvent.preventDefault();
  };

  const onDocumentClick = (event) => {
    if (!topbarItemClick) {
      setState((prev) => {
        return { ...prev, activeTopbarItem: null, topbarMenuActive: false };
      });
    }
    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setState((prev) => {
          return { ...prev, menuActive: false };
        });
      }
      hideOverlayMenu();
    }

    if (state.inlineProfileActive && !inlineProfileClick && isSlim()) {
      setState((prev) => {
        return { ...prev, inlineProfileActive: false };
      });
    }

    if (!configClick) {
      setState((prev) => {
        return { ...prev, configDialogActive: false };
      });
    }
    topbarItemClick = false;
    menuClick = false;
    configClick = false;
    inlineProfileClick = false;

  };
  const hideOverlayMenu = () => {
    setState((prev) => {
      return {
        ...prev,
        overlayMenuActive: false,
        staticMenuMobileActive: false,
      };
    });
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isHorizontal = () => {
    return state.layoutMode === "horizontal";
  };

  const isSlim = () => {
    return state.layoutMode === "slim";
  };

  let menuGrouped = [
    {
      items: [
        { label: t("app_menu.dashboard"), icon: "pi pi-fw pi-home", to: "/home" },
        { label: t("app_menu.payments"), icon: "pi pi-fw pi-credit-card", to: "/payments" },
        { label: t("app_menu.customers"), icon: "pi pi-users", to: "/subscribers" },
        { label: t("app_menu.invitations"), icon: "pi pi-fw pi-send", to: "/invitations" },
        //This (item)screen has been commented out for now but might be needed in the future
        // {
        //   label: t("app_menu.money"),
        //   icon: "pi pi-fw pi-money-bill",
        //   items: [
        //     {
        //       label: t("app_menu.send_money"),
        //       icon: "pi pi-fw pi-send",
        //       to: "/money/send"
        //     },
        //     {
        //       label: t("app_menu.transactions"),
        //       icon: "pi pi-fw pi-list",
        //       to: "/money/withdrawals"
        //     },
        //     {
        //       label: t("app_menu.product_accounts"),
        //       icon: "pi pi-fw pi-wallet",
        //       to: "/money/product_accounts"
        //     },
        //     {
        //       label: t("app_menu.saved_recipients"),
        //       icon: "pi pi-fw pi-save",
        //       to: "/money/saved_recipients"
        //     }
        //   ]

        // },
        {
          label: t("app_menu.reports"),
          icon: "pi pi-chart-line",
          items: [
            {
              label: t("app_menu.subs_summary"),
              icon: "pi pi-book",
              to: "/reports/subscriptions/snapshot",
            },
            //This (item)screen has been commented out for now but might be needed in the future
            // {
            //   label: t("app_menu.transaction_summary"),
            //   icon: "pi pi-wallet",
            //   to: "/reports/withdrawals/snapshot",
            // },
            {
              label: t("app_menu.subs_custom"),
              icon: "pi pi-table",
              to: "/reports/subscription/custom",
            },
            //This (item)screen has been commented out for now but might be needed in the future
            // {
            //   label: t("app_menu.transaction_custom"),
            //   icon: "pi pi-table",
            //   to: "/reports/withdrawals/custom",
            // }
          ]
        },
        { label: t("app_menu.products"), icon: "pi pi-fw pi-list", to: "/products" },
      ],
    },
  ];

  let menuWithRestrictions = [
    {
      items: [
        { label: t("app_menu.payments"), icon: "pi pi-fw pi-credit-card", to: "/payments" },
        { label: t("app_menu.customers"), icon: "pi pi-users", to: "/subscribers" },
        { label: t("app_menu.invitations"), icon: "pi pi-fw pi-send", to: "/invitations" },
        { label: t("app_menu.products"), icon: "pi pi-fw pi-list", to: "/products" },
      ],
    },
  ];

  let entMenuWithRestrictions = [
    {
      items: [
        { label: t("app_menu.payments"), icon: "pi pi-fw pi-credit-card", to: "/payments" },
        { label: t("app_menu.customers"), icon: "pi pi-users", to: "/subscribers" },
        { label: t("app_menu.invitations"), icon: "pi pi-fw pi-send", to: "/invitations" },
        { label: t("app_menu.products"), icon: "pi pi-fw pi-list", to: "/products" },
      ],
    },
  ];

  let businessTypeMenu = [
    {
      items: [
        { label: t("app_menu.dashboard"), icon: "pi pi-fw pi-home", to: "/home" },
        { label: t("app_menu.payments"), icon: "pi pi-fw pi-credit-card", to: "/payments" },
        { label: t("app_menu.customers"), icon: "pi pi-users", to: "/subscribers" },
        {
          label: t("app_menu.subscriptions"),
          icon: "pi pi-fw pi-users",
          items: [
            {
              label: t("app_menu.activity"),
              icon: "pi pi-fw pi-history",
              to: "/subscriptions/activity",
            },
            {
              label: t("app_menu.invitations"),
              icon: "pi pi-fw pi-send",
              to: "/subscriptions/invitations",
            }
          ]
        },
        {
          label: t("app_menu.reports"),
          icon: "pi pi-chart-line",
          items: [
            {
              label: t("app_menu.subs_summary"),
              icon: "pi pi-book",
              to: "/reports/subscriptions/snapshot",
            },
            {
              label: t("app_menu.transaction_summary"),
              icon: "pi pi-wallet",
              to: "/reports/withdrawals/snapshot",
            },
            {
              label: t("app_menu.subs_custom"),
              icon: "pi pi-table",
              to: "/reports/subscription/custom",
            },
            {
              label: t("app_menu.transaction_custom"),
              icon: "pi pi-table",
              to: "/reports/withdrawals/custom",
            }
          ]
        },
        { label: t("app_menu.products"), icon: "pi pi-fw pi-list", to: "/products" },
      ],
    },
  ];


  const layoutClassName = classNames("layout-wrapper", {
    "layout-horizontal": state.layoutMode === "horizontal",
    "layout-overlay": state.layoutMode === "overlay",
    "layout-static": state.layoutMode === "static",
    "layout-slim": state.layoutMode === "slim",
    "layout-static-inactive": state.staticMenuDesktopInactive,
    "layout-mobile-active": state.staticMenuMobileActive,
    "layout-overlay-active": state.overlayMenuActive,
    "layout-menu-dark": state.darkMenu,
    "layout-menu-light": !state.darkMenu,
  });

  return (
    <>
    <div className={layoutClassName} onClick={onDocumentClick}>
      <IdleLogout keycloak={keycloak} />
      <AppTopbar
        topbarMenuActive={state.topbarMenuActive}
        activeTopbarItem={state.activeTopbarItem}
        onMenuButtonClick={onMenuButtonClick}
        onTopbarMenuButtonClick={onTopbarMenuButtonClick}
        onTopbarItemClick={onTopbarItemClick}
        profileMode={state.profileMode}
        horizontal={isHorizontal()}
      />

      <div className="layout-menu-container" onClick={onMenuClick}>
        <div className="layout-menu-logo">
          <button className="p-link" style={{ backgroundColor: "#FE3031" }}>
            <img
              id="layout-menu-logo"
              src={logo}
              alt="babylon-layout"
              style={{ width: "50%", height: "auto" }}
            />
          </button>
        </div>
        <div className="layout-menu-wrapper" style={{ height: "auto" }}>
          <div className="menu-scroll-content">
            {/* if new member or data is not in don"t show menu else show the menu */}
            {(!defMembership && profile) || (!defMembership && !profile) ? (
              <div></div>
            ) : (
              <>
                <div className="id-container">
                  <span className="business-account-id">{state.businessAccount.keyString}</span>
                </div>
                {(["VIEWER", "MANAGER"].includes(defMembership?.role) && !defMembership?.business?.enterprise) ?
                  <AppMenu
                    model={menuWithRestrictions}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    layoutMode={state.layoutMode}
                    active={state.menuActive}
                  /> : (["VIEWER", "MANAGER"].includes(defMembership?.role) && defMembership?.business?.enterprise) ?
                    <AppMenu
                      model={entMenuWithRestrictions}
                      onMenuItemClick={onMenuItemClick}
                      onRootMenuItemClick={onRootMenuItemClick}
                      layoutMode={state.layoutMode}
                      active={state.menuActive}
                    /> :
                    (defMembership?.business?.enterprise ?
                      <AppMenu
                        model={businessTypeMenu}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={state.layoutMode}
                        active={state.menuActive}
                      /> :
                      <AppMenu
                        model={menuGrouped}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={state.layoutMode}
                        active={state.menuActive}
                      />
                    )
                }
              </>
            )}
          </div>
        </div>
      </div>

      <div className="layout-main">
        {accessTokenExists && <>
          <Notification defMembership={defMembership} />
          <Breadcrumbs />

          <div className="layout-content">
            <Suspense fallback={<Loading />}>
             {!defMembership ? <div style={{height: "100vh"}}><Loading/></div>: <Routes>
                {componentRoutes.map(({ component, path, breadcrumb }, key) => {
                 
                  return <Route path={path} element={component} key={key} exact={true} breadcrumb={t(breadcrumb)}/>
                })}
              </Routes>}
            </Suspense>
          </div>
        </>
        }
      </div>

      <AppFooter />

      {state.staticMenuMobileActive && <div className="layout-mask"></div>}

    </div> 

    </>
  );
};

export default App;