import { call, put } from "redux-saga/effects";
import BusinessCustomersActions from "./business-customer.reducer";
import * as api from "../../../Service/api";

export function* getBusinessCustomers(action) {
    const { businessId, options } = action;
    // make the call to the api
    const response = yield call(api.getBusinessCustomers, businessId, options);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerAllSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerAllFailure(response.data))
    }
}

export function* getFilteredBusinessCustomers(action) {
    const { businessId, query } = action

    const response = yield call(api.getFilteredBusinessCustomers, businessId, query)

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerSearchSuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.businessCustomerSearchFailure(response.data))
    }
}
export function* getBusinessCustomerSummarySearch(action) {
    const { businessId, query } = action

    const response = yield call(api.getBusinessCustomerSummarySearch, businessId, query)

    // success?
    if (response.ok) {   
        yield put(BusinessCustomersActions.businessCustomerSummarySearchSuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.businessCustomerSummarySearchFailure(response.data))
    }
}
export function* getBusinessCustomerSummary(action) {
    const { businessId, options } = action

    const response = yield call(api.getBusinessCustomerSummary, businessId, options)

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerSummarySuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.businessCustomerSummaryFailure(response.data))
    }
}

export function* getExactFilteredBusinessCustomers(action) {
    const { businessId, query, options } = action

    const response = yield call(api.getExactFilteredBusinessCustomers, businessId, query, options)

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.exactFilteredBusinessCustomerSuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.exactFilteredBusinessCustomersFailure(response.data))
    }
}

export function* updateEnterpriseCustomer(action) {
    const { businessCustomerId, businessCustomer } = action;
    // make the call to the api
    const response = yield call(api.updateEnterpriseCustomers, businessCustomerId, businessCustomer);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerUpdateSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerUpdateFailure(response.data))
    }
}

export function* getBusinessCustomerSubscriptions(action) {
    const { businessCustomerId } = action

    const response = yield call(api.getBusinessCustomerSubscriptions, businessCustomerId)

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionsSuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionsFailure(response.data))
    }
}

export function* getBusinessCustomerRecord(action) {
    const { businessCustomerId } = action

    const response = yield call(api.getBusinessCustomerRecord, businessCustomerId)

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerRecordSuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.businessCustomerRecordFailure(response.data))
    }
}

export function* getCount(action) {
    const { businessCustomerId } = action

    const response = yield call(api.getBusinessCustomerSubscriptionsCount, businessCustomerId)

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionsCountSuccess(response.data))
    } else {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionsCountFailure(response.data))
    }
}
export function* uploadCustomers(action) {
    const { businessId, membershipId, file } = action;
    // make the call to the api
    const response = yield call(api.businessCustomerImport, businessId, membershipId, file);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerUploadSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerUploadFailure(response.data))
    }
}

export function* getImportCustomers(action) {
    const { businessId, options } = action;
    // make the call to the api
    const response = yield call(api.getBusinessCustomerImports, businessId, options);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerImportSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerImportFailure(response.data))
    }
}

export function* downloadSampleCsv() {
    // make the call to the api
    const response = yield call(api.businessCustomerCsvDownload);
    // success?
    if (response.ok) {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'customer_import_template.csv'); // Set the file name

        // Append the link to the body and click it to trigger download
        document.body.appendChild(link);
        link.click();
        yield put(BusinessCustomersActions.downloadCsvSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.downloadCsvFailure(response.data))
    }
}

export function* getImportCustomersCount(action) {
    const { businessId } = action;
    // make the call to the api
    const response = yield call(api.getBusinessCustomerImportsCount, businessId,);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerImportCountSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerImportCountFailure(response.data))
    }
}

export function* getEntries(action) {
    const { importId } = action;
    // make the call to the api
    const response = yield call(api.getBusinessCustomerEntriesList, importId);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerEntrySuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerEntryFailure(response.data))
    }
}

export function* applyImport(action) {
    const { id } = action;
    // make the call to the api
    const response = yield call(api.applyBusinessCustomerImport, id);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerApplySuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerApplyFailure(response.data))
    }
}

export function* getCustomerRecentPayments(action) {
    const { businessCustomerId, size } = action;
    // make the call to the api
    const response = yield call(api.customerRecentPayments, businessCustomerId, size);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerPaymentsSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerPaymentsFailure(response.data))
    }
}

export function* getCustomerRecentMissedPayments(action) {
    const { businessCustomerId, size } = action;
    // make the call to the api
    const response = yield call(api.customerMissedPayments, businessCustomerId, size);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerMissedPaymentsSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerMissedPaymentsFailure(response.data))
    }
}

export function* getRecentCustomerSubscriptionActivity(action) {
    const { businessCustomerId, size } = action;
    // make the call to the api
    const response = yield call(api.customerSubscriptionActivity, businessCustomerId, size);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionActivitySuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionActivityFailure(response.data))
    }
}

export function* getCustomerReceivedPayments(action) {
    const { businessCustomerId, options } = action;
    // make the call to the api
    const response = yield call(api.customerReceivedPayments, businessCustomerId, options);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.customerReceivedPaymentSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.customerReceivedPaymentFailure(response.data))
    }
}

export function* getCustomerMissedPayments(action) {
    const { businessCustomerId } = action;
    // make the call to the api
    const response = yield call(api.businessCustomerMissedPayments, businessCustomerId);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.customerMissedPaymentSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.customerMissedPaymentFailure(response.data))
    }
}

export function* getCustomerPendingPayments(action) {
    const { businessCustomerId } = action;
    // make the call to the api
    const response = yield call(api.customerPendingPayments, businessCustomerId);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.customerPendingPaymentSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.customerPendingPaymentFailure(response.data))
    }
}

export function* getCustomerReports(action) {
    const { businessId, options } = action;
    // make the call to the api
    const response = yield call(api.getCustomerReport, businessId, options);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.customerReportSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.customerReportFailure(response.data))
    }
}
export function* getBusinessCustomerCharts(action) {
    const { businessId, duration, options } = action;
    // make the call to the api
    const response = yield call(api.getCustomerCharts, businessId, duration, options);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.customerChartsSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.customerChartsFailure(response.data))
    }
}
export function* getSuccessfulPayments(action) {
    const { bcId, options } = action;
    // make the call to the api
    const response = yield call(api.successfulCustomerPayments, bcId, options);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.successfulPaymentsSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.successfulPaymentsFailure(response.data))
    }
}
export function* getExpiredPayments(action) {
    const { bcId, options } = action;
    // make the call to the api
    const response = yield call(api.expiredCustomerPayments, bcId, options);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.expiredPaymentsSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.expiredPaymentsFailure(response.data))
    }
}
export function* getBusinessCustomersBulkPause(action) {
    const { membershipId, ids } = action;
    // make the call to the api
    const response = yield call(api.businessCustomersBulkPause, membershipId, ids);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomersBulkPauseSuccess(response.data.processed));
    } else {
        yield put(BusinessCustomersActions.businessCustomersBulkPauseFailure(response.data))
    }
}

export function* getBusinessCustomersBulkCancel(action) {
    const { membershipId, ids } = action;
    // make the call to the api
    const response = yield call(api.businessCustomersBulkCancel, membershipId, ids);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomersBulkCancelSuccess(response.data.processed));
    } else {
        yield put(BusinessCustomersActions.businessCustomersBulkCancelFailure(response.data))
    }
}
export function* getBusinessCustomersSinglePause(action) {
    const { businessCustomerId, membershipId } = action;
    // make the call to the api
    const response = yield call(api.businessCustomersSinglePause, businessCustomerId, membershipId);
    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomersSinglePauseSuccess(response.data.processed));
    } else {
        yield put(BusinessCustomersActions.businessCustomersSinglePauseFailure(response.data))
    }
}

export function* getBusinessCustomersSingleCancel(action) {
    const { businessCustomerId, membershipId } = action;
    // make the call to the api
    const response = yield call(api.businessCustomersSingleCancel, businessCustomerId, membershipId);

    // success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomersSingleCancelSuccess(response.data.processed));
    } else {
        yield put(BusinessCustomersActions.businessCustomersSingleCancelFailure(response.data))
    }
}
export function* getBusinessCustomerSubscriptionAmounts(action) {
    const { bcId } = action;
    // make the call to the api
    const response = yield call(api.getBusinessCustomerSubscriptionAmounts, bcId);

    //success?
    if (response.ok) {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionAmountsSuccess(response.data));
    } else {
        yield put(BusinessCustomersActions.businessCustomerSubscriptionAmountsFailure(response.data))
    }
}
// takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_ALL_REQUEST, getBusinessCustomers)
