import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import BusinessCustomersActions from '../../Redux/transition/customers/business-customer.reducer';
import SubscriberActions from "../../Redux/transition/subscriber/subscriber.reducer";
import PaymentScheduleActions from "../../Redux/transition/payment-schedule/payment-schedule.reducer";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import "../../Styles/pages/subscriptionDetail.scss"
import "../../Styles/pages/activities.scss";
import "../../Styles/pages/customers.scss";
import "../../Styles/pages/product.scss"
import "../../Styles/pages/subscriptions.scss"
import "../../Styles/pages/inviteSubscriber.scss"
import CustomDataTable from "../../Components/CustomDataTable";
import moment from "moment";
import CustomerEdit from "../Customers/edit";
import InviteSubscriber from "../Subscriptions/Invitation/InviteSubscriber";
import { formatTime } from "../../Pages/Subscriptions/Constant"
import { Paginator } from 'primereact/paginator';
import MarkAsPaid from "../../Components/MarkAsPaid";
import DetailCard from "./SubscriberDetailCard";


const BusinessCustomers = () => {

    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const { id: customerId } = useParams();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [visible, setVisible] = useState(false)
    const cancelToast = useRef(null)
    const pauseToast = useRef(null)
    const resumeToast = useRef(null)
    const rescheduleToast = useRef(null)
    const [confirmPause, setConfirmPause] = useState(false)
    const [confirmResume, setConfirmResume] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [confirmReschedule, setConfirmReschedule] = useState(false)
    const [date, setDate] = useState(null);
    const [activeTab, setActiveTab] = useState(0)
    const [visibleInvite, setVisibleInvite] = useState(false);

    const [state, setState] = useState({
        businessCustomers: null,
        count: null,
        page: 0,
        sort: "id,asc",
        loading: true,
        businessCustomerSubscriptions: [],
        businessCustomerSubscriptionAmounts: null,
        customerRecord: {
            id: "",
            firstName: "",
            lastName: "",
            customerNum: "",
            email: "",
        },
        recentPaymentsObject: [],
        recentMissedPaymentsObject: [],
        recentSubscriptionActivityObject: [],
        planGroup: {},
        visibleCreate: false,
        visible: false,
        totalPendingAmount: null,
        totalMissedAmount: null,
        customerSummary: [],
        paused: false,
        cancelled: false,
        resumed: false,
        subscriptionId: null,
        updating: false,
        firstName: "",
        lastName: "",
        customerNum: "",
        email: "",
        upcomingPayments: [],
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        data: null,
        key: false,
        rescheduled: false,
        successfulPaymentsObject: {},
        expiredPaymentsObject: {},
        totalCustomerSummaryCount: 0,
        id: null,
        customerExternalPayments: [],
        flex: null,
        first: 0,
        colSort: 'receivedAt,desc',
        extSort: "externallyReceivedOn,desc",
        expSort: "scheduledFor,desc",
        upSort: "scheduledFor,asc",
        size: 15,
        tabChange: true,
        mount: false
    })

    const routeChange = (pathname, state) => {
        navigate(pathname, state)
    };

    //Redux State from Store
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessCustomerSubscriptions = useSelector((state) => state.businessCustomers.businessCustomerSubcriptions)
    const fetchingCustomerSubscriptions = useSelector((state) => state.businessCustomers.fetchingbusinessCustomerSubscriptions)
    const fetchingCustomerCollectedPayments = useSelector((state) => state.businessCustomers.fetchingSuccessfulPayments)
    const businessCustomerRecord = useSelector((state) => state.businessCustomers.businessCustomerRecord)
    const fetchingCustomerRecord = useSelector((state) => state.businessCustomers.fetchingBusinessCustomerRecord)
    const recentPayments = useSelector((state) => state.businessCustomers.recentPayments)
    const recentMissedPayments = useSelector((state) => state.businessCustomers.recentMissedPayments)
    const recentSubscriptionActivity = useSelector((state) => state.businessCustomers.recentSubscriptionActivity)
    const customerPendingPayments = useSelector((state) => state.businessCustomers.customerPendingPayments)
    const customerReceivedPayments = useSelector((state) => state.businessCustomers.customerReceivedPayments)
    const cancelledSubscription = useSelector((state) => state.subscribers.cancelledSubscription)
    const cancellingSubscription = useSelector((state) => state.subscribers.cancellingSubscription)
    const pausingSubscription = useSelector((state) => state.subscribers.pausingSubscription)
    const pausedSubscription = useSelector((state) => state.subscribers.pausedSubscription)
    const resumingSubscription = useSelector((state) => state.subscribers.resumingSubscription)
    const resumedSubscription = useSelector((state) => state.subscribers.resumedSubscription)
    const rescheduledSubscription = useSelector((state) => state.subscribers.rescheduledSubscription)
    const reschedulingSubscription = useSelector((state) => state.subscribers.reschedulingSubscription)
    const successfulPayments = useSelector((state) => state.businessCustomers.successfulPayments)
    const fetchingCustomerReceivedPayments = useSelector((state) => state.businessCustomers.fetchingCustomerReceivedPayments)
    const fetchingExpiredPayments = useSelector((state) => state.businessCustomers.fetchingExpiredPayments)
    const businessCustomerPayments = useSelector((state) => state.paymentTracks.businessCustomerPayments)
    const allUpcomingPaymentSchedules = useSelector((state) => state.paymentSchedules.paymentSchedulesPending);
    const businessCustomerSubscriptionAmounts = useSelector((state) => state.businessCustomers.businessCustomerSubscriptionAmounts);

    //Dispatch Actions
    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
    const getEnterpriseCustomers = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerAllRequest(businessId)), [dispatch]);
    const getBusinessCustomerSubscriptions = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerSubscriptionsRequest(businessCustomerId)), [dispatch]);
    const getBusinessCustomerRecord = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerRecordRequest(businessCustomerId)), [dispatch]);
    const getRecentCustomerPayments = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerPaymentsRequest(businessCustomerId, size)), [dispatch]);
    const getRecentMissedCustomerPayments = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerMissedPaymentsRequest(businessCustomerId, size)), [dispatch]);
    const getRecentSubscriptionActivity = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerSubscriptionActivityRequest(businessCustomerId, size)), [dispatch]);
    const getCustomerPendingPayments = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.customerPendingPaymentRequest(businessCustomerId)), [dispatch]);
    const getCustomerReceivedPayments = useCallback((businessCustomerId, options) => dispatch(BusinessCustomersActions.customerReceivedPaymentRequest(businessCustomerId, options)), [dispatch]);
    const cancelSubscriptions = useCallback((subscriptionId, membershipId) => { dispatch(SubscriberActions.cancelSubscriptionRequest(subscriptionId, membershipId)) }, [dispatch])
    const pauseSubscriptions = useCallback((subscriptionId, membershipId) => { dispatch(SubscriberActions.pauseSubscriptionRequest(subscriptionId, membershipId)) }, [dispatch])
    const resumeSubscriptions = useCallback((id, startFrom, membershipId, action) => { dispatch(SubscriberActions.resumeSubscriptionRequest(id, startFrom, membershipId, action)) }, [dispatch])
    const rescheduleSubscriptions = useCallback((subscriberId, startFrom, membershipId) => { dispatch(SubscriberActions.rescheduleSubscriptionRequest(subscriberId, startFrom, membershipId)) }, [dispatch])
    const getSuccessfulPayments = useCallback((bcId, options) => { dispatch(BusinessCustomersActions.successfulPaymentsRequest(bcId, options)) }, [dispatch])
    const getBusinessCustomerUpcomingPayments = useCallback((subscriberId, options) => { dispatch(PaymentScheduleActions.paymentSchedulePendingRequest(subscriberId, options)) }, [dispatch]);
    const getBusinessCustomerSubscriptionAmounts = useCallback((bcId) => { dispatch(BusinessCustomersActions.businessCustomerSubscriptionAmountsRequest(bcId)) }, [dispatch])


    useEffect(() => {
        if (!defMembership) {
            getMembership();
            setState((state) => {
                return {
                    ...state,
                    mount: true
                }
            })
        }
        if (defMembership) {
            getEnterpriseCustomers(defMembership.business.id)
            getBusinessCustomerSubscriptions(customerId)
            getBusinessCustomerRecord(customerId)
            getRecentCustomerPayments(customerId, "3")
            getRecentMissedCustomerPayments(customerId, "3")
            getRecentSubscriptionActivity(customerId, "3")
            getBusinessCustomerUpcomingPayments(customerId, { sort: state.upSort, size: state.size })
            getCustomerPendingPayments(customerId)
            getCustomerReceivedPayments(customerId, { sort: state.sort, size: state.size })
            getSuccessfulPayments(customerId, { sort: state.colSort, size: state.size })
            getBusinessCustomerSubscriptionAmounts(customerId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (businessCustomerSubscriptions) {
            setState((state) => {
                return {
                    ...state,
                    businessCustomerSubscriptions,
                    loading: false,
                }
            })
        }
        if (businessCustomerRecord) {
            setState((state) => {
                return {
                    ...state,
                    customerRecord: businessCustomerRecord
                }
            })
        }
        if (recentPayments) {
            setState((state) => {
                return { ...state, recentPaymentsObject: recentPayments }
            })
        }
        if (recentMissedPayments) {
            setState((state) => { return { ...state, recentMissedPaymentsObject: recentMissedPayments } })
        }
        if (recentSubscriptionActivity) {
            setState((state) => { return { ...state, recentSubscriptionActivityObject: recentSubscriptionActivity } })
        }
        if (customerPendingPayments) {
            setState((state) => { return { ...state, totalPendingAmount: customerPendingPayments } })
        }
        if (allUpcomingPaymentSchedules) {
            setState((state) => ({ ...state, upcomingPayments: allUpcomingPaymentSchedules }))
        }

        if (customerReceivedPayments) {
            setState((state) => {
                return {
                    ...state, customerSummary: customerReceivedPayments?.content,
                    totalCustomerSummaryCount: customerReceivedPayments?.totalElements
                }
            })
        }
        if (successfulPayments) {
            setState((state) => { return { ...state, successfulPaymentsObject: successfulPayments?.content } })
        }
        if (businessCustomerPayments) {
            setState((state) => { return { ...state, customerExternalPayments: businessCustomerPayments?.content } })
        }
        if (businessCustomerSubscriptionAmounts) {
            setState((state) => {
                return { ...state, businessCustomerSubscriptionAmounts }
            })
        }
    }, [businessCustomerSubscriptions,
        businessCustomerRecord,
        recentPayments,
        customerReceivedPayments,
        recentMissedPayments,
        customerPendingPayments,
        recentSubscriptionActivity,
        successfulPayments,
        businessCustomerPayments,
        state.customerExternalPayments,
        businessCustomerSubscriptionAmounts,
        allUpcomingPaymentSchedules
    ])

    useEffect(() => {
        if (!cancellingSubscription && cancelledSubscription && state.cancelled) {
            setState((state) => { return { ...state, cancelled: false, response: cancelledSubscription } })
            cancelSuccess()
            onRefresh()
        }
        if (!pausingSubscription && pausedSubscription && state.paused) {
            setState((state) => { return { ...state, paused: false } })
            pauseSuccess()
            onRefresh()
        }
        if (!resumingSubscription && state.key && state.resumed) {
            setState((state) => { return { ...state, resumed: false, key: false } })
            resumeSuccess()
            onRefresh()
        }
        if (!reschedulingSubscription && state.key && state.rescheduled) {
            setConfirmReschedule(false)
            setState((state) => { return { ...state, rescheduled: false, key: false } })
            rescheduleSuccess()
            onRefresh(true)
        }
        if (!reschedulingSubscription && state.key && state.rescheduled) {
            setConfirmReschedule(false)
            setState((state) => { return { ...state, rescheduled: false, key: false } })
            rescheduleSuccess()
            onRefresh(true)
        }
        if (state.tabChange && state.mount === true) {
            setActiveTab(tabs[activeTab]?.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancellingSubscription, state.cancelled, pausingSubscription, pausedSubscription, state.paused, state.tabChange,
        resumingSubscription, resumedSubscription, state.resumed, state.key, state.rescheduled, rescheduledSubscription, state.mount])

    useEffect(() => {
        switch (activeTab) {
            case 1:
                getSuccessfulPayments(customerId, { sort: state.colSort, size: state.size });
                break;
            default:
                getBusinessCustomerSubscriptions(customerId, { page: state.page, sort: state.sort, size: state.size });
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    if (fetchingCustomerRecord) {
        return <Loading />
    }

    const dateFormat = (date) => {
        return <div className="date-style">
            <p className="mb-0 mt-1 date">{moment(date).format("MMMM D, YYYY")}</p>
            <p className="mb-0 time">{moment(date).format("h:mm A")}</p>
        </div>
    }


    // toast for when a subscription is cancelled successfully
    const cancelSuccess = () => {
        cancelToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.cancelled'), life: 3000 })
    }

    // toast for when a subscription is paused successfully
    const pauseSuccess = () => {
        pauseToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.paused'), life: 3000 })
    }

    // toast for when a subscription is resumed successfully
    const resumeSuccess = () => {
        resumeToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.resumed'), life: 3000 })
    }

    const rescheduleSuccess = () => {
        rescheduleToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.rescheduled'), life: 3000 })
    }

    const onRefresh = () => {
        window.location.reload(true);
    }

    const paymentBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                {
                    rowData ?
                        <div>
                            <span className="successCount_span">
                                {rowData.subscriber.successCount !== null ? rowData.subscriber.successCount : "0"}
                            </span>
                            <span className="num_payments">{t("common.payments")}</span>
                        </div> : ""
                }
                <div>
                    <span className='business-customer-amnt-text'>
                        {defMembership.business.country.currency}{" "}
                        {rowData?.collectedAmount !== null ? rowData.collectedAmount?.toFixed(2) : "0.00"}
                        <span className="cus_payments">{" "}  {t("business_customers.paid")}</span>
                    </span>
                </div>
                <div>
                    <span className='tier-customer-amnt-text'>
                        {defMembership.business.country.currency} {rowData.redeemable !== null ? rowData.redeemable.toFixed(2) : "0.00"} <span className="tier-customer-amnt-text">
                            <span className="cus_revenue">{t("common.revenue")}</span>
                        </span>
                    </span>
                </div>
                <div>{rowData.subscriber.plan.flex ?
                    <div><span className="missed_count_number">{rowData.missedCount}</span> <span className="missed_count">{t("subscriber.missed")}</span></div> :
                    <span className='business-customer-missed-amnt-text'>
                        {defMembership.business.country.currency} {rowData.missedAmount?.toFixed(2)} <span className="cus_missed">
                            {t("business_customers.missed")}
                        </span>
                    </span>}
                </div>
                {
                    rowData.status === "ACTIVE" || rowData.status === "PAUSED" ?
                        <div >
                            <span className="cus_next_payment"> {t("subscriber.next_payment")}</span>
                            <span className="cus_time">{formatTime(rowData.subscriber?.nextBillingDate)}</span>
                        </div> : null
                }
            </div>
        }
    }
    const scheduledForBodyTemplate = (rowData) => {
        return <div className="flex flex-column align-items-start scheduleed">
            <p className={`subscription-status scheduleed-status-box status-${rowData.status}`}>
                {rowData.status.toUpperCase()}</p>
            <p className="scheduleed-title mb-2">{t("subscriber.scheduled_for")}</p>
            <p className="mb-0 scheduleed-date">{rowData.scheduledFor ? moment(rowData.scheduledFor).format("MMMM D, YYYY") : "--"}</p>
            <p className="mb-0 scheduleed-time">{rowData.scheduledFor ? moment(rowData.scheduledFor).format("h:mm A") : "--"}</p>
        </div>
    }

    const subscriptionsBodyTemplate = (rowData) => {
        return <div className="flex flex-column align-items-start cust">
            <p className="mb-0 cust-num">{rowData?.subscription?.plan?.name}</p>
            <p className="mb-0 pb-0 cust-name">{rowData?.subscription?.businessCustomer?.name}</p>
            <p className="mb-0 pb-0 cust-phoneNo">{rowData?.subscription?.businessCustomer?.phoneNo}</p>
        </div>
    }

    const upcomingAmount = (rowData) => {
        return <div className="flex flex-column align-items-start">
            {rowData?.subscription?.plan?.flex ? <p className="upcoming-amount">{t("business_customers.flex")}</p>
                :
                <p className="upcoming-amount">{`${defMembership.business.country.currency}
             ${rowData?.cost ? rowData.cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "--"}`}</p>}
        </div>
    }

    const statusBodyTemplate = (rowData) => {
        if (rowData.status === "ACTIVE") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content" >
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status_body`}>{rowData.status}
                    </span>
                </div>
                <div className="status-date-content">
                    <p>{dateFormat(rowData.subscriber?.createdDate)}</p>
                </div>
            </div>
        } else if (rowData.status === "CANCELLED") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content">
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status_body `}>{rowData.status}</span>
                </div>
                {
                    rowData.cancelledOn !== null ?
                        <p>{dateFormat(rowData.subscriber?.cancelledOn)}</p> : ""
                }
            </div>
        } else if (rowData.status === "PAUSED") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content">
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status_body `}>{rowData.status}</span>
                </div>
                {
                    rowData.pausedOn !== null ?
                        <p>{dateFormat(rowData.subscriber?.pausedOn)}</p> : ""
                }
            </div>
        } else if (rowData.status === "COMPLETE") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content">
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status_body `}>{rowData.status}</span>
                </div>
                {
                    rowData.completedOn !== null ?
                        <p>{dateFormat(rowData.subscriber?.completedOn)}</p> : ""
                }
            </div>
        } else {
            return ""
        }
    }

    const subscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                    {
                        rowData.subscriber.subscriberReference !== null ?
                            <p className="reference-text-customers detail-navigation" id="planName"
                                onClick={() => {
                                    if (rowData.id)
                                        routeChange(`/subscribers/subscriber/subscription/${rowData.id}`, rowData);
                                }}
                            >
                                {rowData.subscriber.subscriberReference}</p>
                            : ""
                    }
                    {
                        rowData.subscriber.plan.name ?
                            <span className="detail-navigation tier-name-content"
                                onClick={() => {
                                    navigate(`/products/plan/detail/${rowData.planId}`)
                                }
                                }
                            >
                                {rowData.subscriber.plan.group.name !== null ? rowData.subscriber.plan.name : ""}
                            </span> : null
                    }
                    {
                        rowData.subscriber?.plan?.billingPeriod ?
                            <span className="plan-group-text">
                                {rowData.subscriber.plan.billingPeriod !== null ? t(`product.${rowData.subscriber.plan.billingPeriod.toLowerCase()}`) : ""}
                            </span> : null
                    }
                    {
                        rowData.subscriber.plan.groupName &&
                        <span className="plan-text detail-navigation hov"
                            onClick={() => {
                                navigate(`/products/detail/${rowData.groupId}`)
                            }}
                        >
                            {rowData.subscriber.plan.groupName}
                        </span>
                    }
                    {rowData.subscriber.plan.setup && <div className="setup_fee_main_div setup_pt">
                        <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                        <span className="setup_fee_value"> {defMembership.business.currency}{" "}{rowData.subscriber.plan.setup?.toFixed(2)}</span>
                    </div>}
                </div >
            </div >
        }
    }

    const amountTemplate = (rowData) => {
        if (rowData.subscriber.plan?.policy === "SCHEDULE") {
            return "--"
        } else if (rowData.subscriber.plan?.policy === "SUBSCRIPTION") {
            return <div>
                {
                    rowData.subscriber.cost !== null ?
                        <span>{`${defMembership.business.country.currency} ${rowData.subscriber.cost?.toFixed(2)}`}</span> : "--"
                }
            </div>
        } else if (rowData.subscriber.plan?.policy === "TIER" && rowData.subscriber.plan.flex === false) {
            return <div>
                {
                    rowData.subscriber.plan.cost !== null ?
                        <span>{`${defMembership.business.country.currency} ${rowData.subscriber.plan.cost?.toFixed(2)}`}</span> : "--"
                }
            </div>
        } else if (rowData.subscriber.plan.flex === true) {
            return t("subscriber.flex_true")
        } else if (rowData.subscriber.plan.cost === null) {
            return '--'
        } else {
            return "--"
        }
    }

    const actionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="actionBodyTemplate_div">
                {rowData.status &&
                    <div>
                        <Button
                            icon={"pi pi-info-circle"}
                            className="p-button action-button"
                            tooltip={t("product.details")}
                            onClick={() => {
                                if (rowData.id)
                                    routeChange(`/subscribers/subscriber/subscription/${rowData.id}`, rowData);
                            }}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}>
                        </Button>
                    </div>
                }
                {
                    (rowData.status === "ACTIVE") && (defMembership?.role !== "VIEWER") &&
                    <div>
                        <Button
                            icon={rowData.status === "ACTIVE" && "pi pi-calendar"}
                            className="p-button action-button"
                            tooltip={t('subscriber.reschedule')}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmReschedule(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />
                    </div>
                }
                {
                    (rowData.status === "ACTIVE") && (defMembership?.role !== "VIEWER") &&
                    <div>
                        <Button
                            icon={rowData.status === "ACTIVE" && "pi pi-pause"}
                            className="p-button action-button"
                            tooltip={t("subscriber.pause_label")}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmPause(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />
                    </div>
                }
                {
                    (rowData.status === "PAUSED") && (defMembership?.role !== "VIEWER") &&
                    <div>
                        <Button
                            icon={rowData.status === "PAUSED" && "pi pi-refresh"}
                            className="p-button action-button"
                            tooltip={t("subscriber.resume_label")}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmResume(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />
                    </div>
                }
                <div>
                    {(rowData.status === "ACTIVE" || rowData.status === "PAUSED") && (defMembership?.role !== "VIEWER") &&
                        <Button icon={"pi pi-times-circle"}
                            className="p-button action-button"
                            tooltip={t("subscriber.cancel_label")}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmCancel(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />}
                </div>
            </div >
        }
    }


    const tabs = [{
        label: "subscriber.title", columns: [
            { field: 'status', body: statusBodyTemplate, header: t("business_customers.status"), headerClassName: "amount-header", bodyClassName: "status-body" },
            { field: 'subscriber', body: subscriptionBodyTemplate, header: t("subscriber.info"), headerClassName: "subsTemplate-header", bodyClassName: "status" },
            { field: 'subscriber.cost', body: amountTemplate, header: t("dashboard.amount"), headerClassName: "amount-header", bodyClassName: "status" },
            { field: "collectedAmount", body: paymentBodyTemplate, header: t("business_customers.payment"), headerClassName: "subsTemplate-header" },
            { body: actionBodyTemplate, headerClassName: "subsTemplate-header" },
        ],
        value: state.customerSummary, count: state.totalCustomerSummaryCount, paginator: true, sort: "status,desc", index: 0
    },
    {
        label: "subscriber.upcoming", columns: [
            { field: 'scheduledFor', body: scheduledForBodyTemplate, header: t("products.payment_schedule"), headerClassName: "amount-header", bodyClassName: "status-body" },
            { field: 'subscriber', body: subscriptionsBodyTemplate, header: t("business_customers.subscription_info"), headerClassName: "subsTemplate-header", bodyClassName: "status" },
            { field: 'subscriber.cost', body: upcomingAmount, header: t("dashboard.amount"), headerClassName: "amount-header", bodyClassName: "status", alignHeader: "left" },
        ],
        value: state.upcomingPayments, count: state.upcomingPayments.length, paginator: true, sort: "scheduledFor,desc", index: 1
    }
    ]


    const receivedPayments = state.businessCustomerSubscriptions.map((receivedPayments) => {
        return (receivedPayments.status === "ACTIVE" && receivedPayments.successCount)
    })

    const activeSubscriptions = state.businessCustomerSubscriptions.map((activeSubscriptions) => {
        return (activeSubscriptions.status === "ACTIVE" ? 1 : 0)
    })

    const totalActiveSubscriptions = activeSubscriptions.reduce((a, b) => a + b, 0);

    const totalReceivedPayments = receivedPayments.reduce((a, b) => a + b, 0);

    const closeDisplayBasic = () => {
        setDisplayBasic(false)
    };


    const pauseSubscriptionActions = (
        <div className="cus_pauseSubscriptionActions">
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" id="pause_no_btn"
                onClick={() => { setConfirmPause(false) }}
            />
            <Button label={t('common.yes')} icon="pi pi-check" loading={pausingSubscription}
                id="pause_yes_btn"
                onClick={() => {
                    pauseSubscriptions(state.subscriptionId, defMembership.id);
                    setState((state) => { return { ...state, paused: true } })
                }}
                autoFocus />
        </div>
    );

    const cancelSubscriptionActions = (
        <div className="cus_cancelSubscriptionActions">
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" id="cancelSubs_no_btn"
                onClick={() => { setConfirmCancel(false) }}
            />
            <Button label={t('common.yes')} icon="pi pi-check" loading={cancellingSubscription}
                id="cancelSubs_yes_btn"
                onClick={() => {
                    cancelSubscriptions(state.subscriptionId, defMembership.id);
                    setState((state) => { return { ...state, cancelled: true } })
                }}
                autoFocus />
        </div>
    );

    const newDate = moment(date).format('YYYY-MM-DD')

    const resumeSubscriptionActions = (
        <div className="cus_resumeSubscriptionActions">
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text"
                id="resume_no_btn"
                onClick={() => { setConfirmResume(false) }}
            />
            <Button label={t('common.yes')} icon="pi pi-check" loading={resumingSubscription} disabled={!date}
                id="resumeSubs_yes_btn"
                onClick={() => {
                    resumeSubscriptions(state.subscriptionId, newDate, defMembership.id, "RESUME")
                    setState((state) => { return { ...state, resumed: true, key: true } })
                }}
                autoFocus />
        </div>
    );

    const rescheduleSubscriptionActions = (
        <div className="subs-actions-footer">
            <Button label={t('subscriber.cancel')} icon="pi pi-times" className="p-button-text"
                id="reschedule_no_btn"
                onClick={() => { setConfirmReschedule(false) }}
            />
            <Button label={t('subscriber.reschedule')} icon="pi pi-check" loading={reschedulingSubscription} disabled={!date}
                id="reschedule_confirm_btn"
                onClick={() => {
                    rescheduleSubscriptions(state.subscriptionId, newDate, defMembership.id);
                    setState((state) => { return { ...state, rescheduled: true, key: true } })
                }}
                autoFocus />
        </div>
    );

    const toggleInvite = () => {
        setVisibleInvite(false)
    }

    let today = new Date();
    const phoneNumber = state.customerRecord?.phoneNo

    const onChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        } else if (tabs[activeTab].index === 0) {
            getCustomerReceivedPayments(customerId, { page: e.page, sort: state.sort, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 1) {
            getRecentSubscriptionActivity(customerId, { page: e.page, sort: state.colSort, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else {
            return;
        }
    }

    const totalNumber = tabs[activeTab].index === 0
        ? state.totalCustomerSummaryCount
        : tabs[activeTab].index === 1
            ? state.recentSubscriptionActivityObject.length
            : null


    const loadingSpinner = fetchingCustomerCollectedPayments ||  fetchingExpiredPayments || fetchingCustomerSubscriptions || fetchingCustomerReceivedPayments;

    return (
        <div className="subscriber-detail-screen">
            <Toast ref={cancelToast} />
            <Toast ref={pauseToast} />
            <Toast ref={resumeToast} />
            < Toast ref={rescheduleToast} />
            <CustomerEdit displayBasic={displayBasic} onHide={closeDisplayBasic} id={state.id} state={state} setState={setState} />
            <InviteSubscriber visible={state.visible} header={t("business_customers.invite")}
                toggleVisible={visibleInvite} onHide={toggleInvite} initialTier={null} phoneNo={phoneNumber} />
            <MarkAsPaid displayDialog={visible} onHide={() => { setVisible(false) }}
                scheduledFor={state.scheduledFor}
                scheduleId={state.id}
                isFlexTrue={state.flex}
                callbackFunctions={() => {
                    getEnterpriseCustomers(defMembership.business.id)
                    getBusinessCustomerSubscriptions(customerId)
                    getBusinessCustomerRecord(customerId)
                    getRecentCustomerPayments(customerId, "3")
                    getRecentMissedCustomerPayments(customerId, "3")
                    getRecentSubscriptionActivity(customerId, "3")
                    getCustomerPendingPayments(customerId)
                    getCustomerReceivedPayments(customerId)
                    getSuccessfulPayments(customerId)
                }}
            />
            <Dialog
                visible={confirmPause}
                style={{ width: "auto" }}
                onHide={() => setConfirmPause(false)}
                header={t("subscriber.confirmation_header")}
                footer={pauseSubscriptionActions}
            >
                {<h6>{t("subscriber.pause_confirmation_message")}</h6>}
            </Dialog>
            <Dialog onHide={() => setConfirmReschedule(false)} visible={confirmReschedule} header={t("subscriber.reschedule_label")}
                footer={rescheduleSubscriptionActions} style={{ width: "23rem" }}>
                {
                    <div className="field  reschedule-date">
                        <Calendar
                            id="startDate"
                            name="startDate"
                            value={date}
                            minDate={today}
                            readOnlyInput
                            onChange={(e) => setDate(e.value)} showIcon
                            placeholder={t("subscriber.rescheduling_date")}
                            className="p-datepicker-header"
                        />
                    </div>
                }
            </Dialog>
            <Dialog
                visible={confirmCancel}
                style={{ width: "auto" }}
                onHide={() => setConfirmCancel(false)}
                header={t("subscriber.confirmation_header")}
                footer={cancelSubscriptionActions}
            >
                {<h6>{t("subscriber.cancel_confirmation_message")}</h6>}
            </Dialog>
            <Dialog onHide={() => setConfirmResume(false)} visible={confirmResume} header={t("subscriber.resume_label")} footer={resumeSubscriptionActions}>
                {
                    <div className="field col-12 md:col-4 " style={{ width: "18rem" }}>
                        <Calendar
                            id="startDate"
                            name="startDate"
                            value={date}
                            minDate={today}
                            readOnlyInput
                            onChange={(e) => setDate(e.value)} showIcon
                            placeholder={t("subscriber.resumption_date")}
                            className="p-datepicker-header"
                        />
                    </div>
                }
            </Dialog>
            <DetailCard {...{
                state, t, totalActiveSubscriptions, defMembership, totalReceivedPayments,
                setDisplayBasic, setState, customerId, setVisibleInvite
            }} />

            <div className="detail_card">
                <CustomDataTable
                    value={tabs[activeTab].value}
                    columns={tabs[activeTab].columns}
                    tabs={tabs.map(({ label, i }) => ({ label, i }))}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    setState={setState}
                    tableClassName="tier_detail_data_table"
                    title="business_customers.table_title"
                    children={null}
                    rows={state.size}
                    loading={loadingSpinner}
                    tab={state.tabChange}
                />
                <Paginator
                    className="customer_detail_pagination"
                    rows={state.size}
                    first={state.first}
                    totalRecords={totalNumber}
                    onPageChange={onChange}
                />
            </div>
        </div >
    )
}
export default BusinessCustomers