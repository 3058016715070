import React from 'react'
import { Card } from 'primereact/card';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import { useTranslation } from "react-i18next";
import { AutoComplete } from "primereact/autocomplete";
import "../../Styles/pages/transactions.scss";
import "../../Styles/pages/payments.scss";

export const ActionBar = ({
    typeSelection, setSelectedType, dropdownItems, selectedOption, updateFirstState,
    summaryOptions, setSelectedOption, selectedCustomer, filteredCustomer, searchCustomers, periods, period, setDates, setPeriod, dateChanged,
    setDateChanged, setTypeChanged, typeChanged, setPage, setSelectedCustomer, setProductChanged, productChanged, setSelectedBcId, setCleared, showSubscriberName, setShowSubscriberName,
    filtered, dates, placeholder, total, productName, setClearName, showCustomDate, calendarDates, handleCalendarSelect, calendarVisible }) => {

    const { t } = useTranslation("common");
    const handleClear = () => {
        setSelectedCustomer('')
        setShowSubscriberName('')
    };

    return (
        <div>
            <Card id="payment-action-bar" style={{ marginBottom: "3rem", width: "auto" }}>
                <div className='col-12'>
                    <div className="p-grid p-fluid main-div">
                        <>
                            {
                                <div className="p-col-12 payment-count" >
                                    <span> <span className='countNumber'>{total ? total : 0}</span> <span className='countText'>{typeSelection?.code === "CP"
                                        ? total <= 1 ? "Collected Payment" : "Collected Payments" : typeSelection?.code === "MP" ?
                                            total <= 1 ? "Missed Payment" : "Missed Payments" : total <= 1 ? "External payment" : "External Payments"}</span></span>
                                </div>
                            }
                            {
                                <div className="p-col-12 p-md-3 filters" >
                                    <div className="p-inputgroup ">
                                        <Dropdown
                                            value={typeSelection}
                                            onChange={setSelectedType}
                                            options={dropdownItems}
                                            optionLabel="name"
                                            className="w-full  activity-dropdown report-plan-customer-dropdown"
                                            id='report-plan-customer-dropdown' />
                                    </div>
                                </div>
                            }
                            <div className="p-col-12 p-md-3 filters autocomplete-container" style={{ display: "flex", alignItems: "center" }}>
                                <div className="p-inputgroup" style={{ width: "18rem" }}>
                                    <AutoComplete field={typeSelection.code === "CP" ? `busCustomerName` : `customerName`}
                                        placeholder={t("payments.search_by")}
                                        value={showSubscriberName ? showSubscriberName : selectedCustomer}
                                        // onSelect={(e) => setSelectedBcId(e.value)}
                                        loadingIcon={false}
                                        className={showSubscriberName ? 'active_dropdown' : ''}

                                        // Auto Suggestions might come in handy later!!

                                        // suggestions={selectedCustomer?.length >= 3 && filteredCustomer}
                                        // suggestions={filteredCustomer}
                                        // suggestions={filtered}
                                        // completeMethod={searchCustomers}
                                        // completeMethod={search}
                                        onChange={setSelectedCustomer} />
                                    {(selectedCustomer || showSubscriberName) && <i className="pi pi-times clear-icon" style={{ fontSize: '1rem', color: "red" }} onClick={() => { handleClear() }}></i>}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3 filters">
                                <div className="p-inputgroup" style={{ width: "auto" }}>
                                    <Dropdown
                                        value={selectedOption}
                                        options={summaryOptions}
                                        optionLabel="name"
                                        placeholder={'Select a product'}
                                        filter
                                        showClear
                                        onChange={setSelectedOption}
                                        id={selectedOption && 'active_dropdown'}
                                        className="w-full activity-dropdown" />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3 filters" >
                                <div className="p-inputgroup">
                                    <Dropdown
                                        className="activity-dropdown"
                                        value={period}
                                        id={period && "period_active_dropdown"}
                                        options={periods}
                                        onChange={setPeriod}
                                        optionLabel="name"
                                    // disabled={dates !== null}
                                    />
                                </div>
                            </div>
                            {showCustomDate && <div className="p-col-12 p-md-4 input-div" style={{ width: "16.5rem" }}>
                                <div className='report_calender'>
                                    <Calendar className="p-datepicker-header report-calender datepicker"
                                        id='report-calender'
                                        value={calendarDates}
                                        showIcon
                                        showButtonBar
                                        onClearButtonClick={() => { setCleared(true) }}
                                        dateFormat="dd M yy"
                                        maxDate={new Date()}
                                        onChange={(e) => {
                                            handleCalendarSelect(e)
                                        }} selectionMode="range"
                                        readOnlyInput
                                        placeholder={t("Choose start and end dates")}
                                    />
                                </div>
                            </div>}
                        </>
                    </div >
                </div>
            </Card>
        </div>
    )
}