import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../Styles/pages/transactions.scss";
import "../../Styles/pages/payments.scss";
import "../../Styles/pages/activities.scss";
import "../../Styles/pages/report.scss"
import "../../Styles/pages/dashboard.scss";
import "../../Styles/pages/subscriptionDetail.scss"
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import BusinessCustomersActions from '../../Redux/transition/customers/business-customer.reducer';
import moment from 'moment';
import { ActionBar } from './actionBar';
import CustomDataTable from "../../Components/CustomDataTable";
import MarkAsPaid from "../../Components/MarkAsPaid";
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';

import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Payments = () => {
    const { t } = useTranslation("common");
    const [displayBasic, setDisplayBasic] = useState(false);
    const [calendarDates, setCalendarDates] = useState(null);
    const [calendarVisible, setCalendarVisible] = useState(false);
    const customDateSelectionRef = useRef(false);

    const [state, setState] = useState({
        enterpriseCustomers: null,
        businessCustomers: [],
        businessCustomerReports: {},
        invitesData: [],
        paymentsData: [],
        subscriptionsData: [],
        invitationInfo: {},
        paymentsInfo: {},
        subscriptionInfo: {},
        subscriberInfo: {},
        options: null,
        date: null,
        planReports: [],
        planCharts: [],
        planInvitesData: [],
        planSubscriptionsData: [],
        planPaymentsData: [],
        input: '',
        submitInput: false,
        selectedCustomer: null,
        filteredCustomers: [],
        id: null,
        isAmountTrue: null,
        first: 0,
        size: 15,
        paginate: false,
        flex: false,
        allProductsObject: [],
        page: 0
    });
    //redux state from store
    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const fetchingCollected = useSelector((state) => state.paymentTracks.fetchingCollectedPayments);
    const fetchingMissed = useSelector((state) => state.paymentTracks.fetchingMissedPayments);
    const fetchingExternal = useSelector((state) => state.paymentTracks.fetchingExternalPayments);
    const collectedPayments = useSelector((state) => state.paymentTracks.collectedPaymentTracks);
    const missPayments = useSelector((state) => state.paymentTracks.missedPaymentTracks);
    const externalPayments = useSelector((state) => state.paymentTracks.externalPaymentTracks);
    const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive);
    const allPlanGroups = useSelector((state) => state.planGroups.planGroups);
    const businessCustomerRecord = useSelector((state) => state.businessCustomers.businessCustomerRecord)

    //redux Dispatch actions
    const dispatch = useDispatch();
    const getMembership = () => dispatch(DefMembershipActions.defMembershipRequest())
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
    const getCollectedPayments = useCallback((businessId, filter, options) => dispatch(PaymentTrackActions.collectedPaymentTrackRequest(businessId, filter, options)), [dispatch]);
    const getMissedPayments = useCallback((businessId, filter, options) => dispatch(PaymentTrackActions.missedPaymentTrackRequest(businessId, filter, options)), [dispatch]);
    const getExternalPayments = useCallback((businessId, filter, options) => dispatch(PaymentTrackActions.externalPaymentTrackRequest(businessId, filter, options)), [dispatch]);
    const getAllPlanGroups = useCallback((businessId, options) => { dispatch(PlanGroupActions.planGroupAllRequest(businessId, options)) }, [dispatch]);
    const getBusinessCustomerRecord = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerRecordRequest(businessCustomerId)), [dispatch]);

    let doc = []
    const dropdownItems = [
        { name: t("payments.collected"), code: 'CP', alias: "collected", actionType: "FETCH_COLLECTED" },
        { name: t("payments.missed"), code: 'MP', alias: "missed", actionType: "FETCH_MISSED" },
        { name: t("payments.external"), code: 'ER', alias: "external", actionType: "FETCH_EXTERNAL" }
    ];

    const periods = [
        { code: "today", name: "Today" },
        { code: "last_week", name: "Last Week" },
        { code: "last_month", name: "Last Month" },
        { code: "last_3_months", name: "Last 3 Months" },
        { code: "last_6_months", name: "Last 6 Months" },
        { code: "last_12_months", name: "Last 12 Months" },
        { code: "custom", name: "Custom Date" }
    ];

    const loading = fetchingCollected || fetchingMissed || fetchingExternal

    const defaultTypeOption = dropdownItems[0];
    const defaultProductOption = null;
    const defaultPeriodOption = periods[3];
    const defaultActionType = dropdownItems[0].actionType;

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [typeSelection, setTypeSelection] = useState(defaultTypeOption);
    const [productSelection, setProductSelection] = useState(defaultProductOption);
    const [periodSelection, setPeriodSelection] = useState(defaultPeriodOption);
    const [searchText, setSearchText] = useState('');
    const [bcId, setBcId] = useState('');
    const [currentActionType, setCurrentActionType] = useState(defaultActionType);
    const [customDate, setCustomDate] = useState('');
    const [showCustomDate, setShowCustomDate] = useState(false);
    const [showSubscriberName, setShowSubscriberName] = useState('')

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getActivePlanGroups(defMembership.business.id);
            getAllPlanGroups(defMembership.business.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (planGroupsGraphedActive) {
            let planGroupAssigned = Object.assign({}, planGroupsGraphedActive);
            planGroupAssigned.groups.map((item, ind) => {
                const {
                    group,
                } = item;

                doc.push({
                    ...group
                });
                return null;
            });
        }
        if (allPlanGroups) {
            setState((state) => { return { ...state, allProductsObject: allPlanGroups } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planGroupsGraphedActive, allPlanGroups])

    const derivePeriodFromDates = (startDate, endDate) => {

        if (periodSelection.code === "custom") return;
        if (!startDate || !endDate) return defaultPeriodOption;

        const start = new Date(startDate);
        const end = new Date(endDate);
        const today = new Date();

        today.setHours(0, 0, 0, 0);

        const diffTime = today.getTime() - start.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays <= 1 && end.toISOString().split('T')[0] === today.toISOString().split('T')[0]) {
            return periods.find(p => p.code === 'today');
        } else if (diffDays <= 7) {
            return periods.find(p => p.code === 'last_week');
        } else if (diffDays <= 30) {
            return periods.find(p => p.code === 'last_month');
        } else if (diffDays <= 90) {
            return periods.find(p => p.code === 'last_3_months');
        } else if (diffDays <= 180) {
            return periods.find(p => p.code === 'last_6_months');
        } else if (diffDays <= 365) {
            return periods.find(p => p.code === 'last_12_months');
        }

        return periods.find(p => p.code === 'custom');
    };

    useEffect(() => {
        if (bcId) {
            getBusinessCustomerRecord(bcId);
        }
        if (businessCustomerRecord) {
            setState((state) => ({ ...state, subscriberInfo: businessCustomerRecord }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bcId])

    useEffect(() => {
        if (bcId && state?.subscriberInfo) {
            const subscriberName = state.subscriberInfo?.name;
            setShowSubscriberName(subscriberName);
        }
    }, [bcId, state.subscriberInfo])

    // Modified useEffect for handling URL parameters and product selection
    useEffect(() => {
        if (!state.allProductsObject || !allPlanGroups) {
            return;
        }

        if (customDateSelectionRef.current) {
            customDateSelectionRef.current = false;
            return;
        }

        const typeCode = searchParams.get('type') || defaultTypeOption.alias;
        const productCode = searchParams.get('product') || '';
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        const text = searchParams.get('searchText') || '';
        const id = searchParams.get('bcId') || '';
        const thirdOptionObj = derivePeriodFromDates(startDate, endDate);
        const customDateValue = thirdOptionObj?.code === 'custom' ? startDate : '';
        const typeOptionObj = dropdownItems.find(option => option.alias === typeCode) || defaultTypeOption;

        let selectedProductObj = null;
        if (productCode && allPlanGroups && allPlanGroups.length > 0) {
            selectedProductObj = allPlanGroups.find(item =>
                item && item.id.toString() === productCode
            );
            if (!selectedProductObj) {
                selectedProductObj = allPlanGroups.find(item =>
                    item && item.id && item.id.toString() === productCode.toLowerCase()
                );
            }
        }

        setTypeSelection(typeOptionObj);

        if (selectedProductObj) {
            setProductSelection(selectedProductObj);
        }

        setPeriodSelection(thirdOptionObj);
        setSearchText(text);
        setBcId(id);
        setCustomDate(customDateValue);
        setShowCustomDate(thirdOptionObj?.code === 'custom');
        setCurrentActionType(typeOptionObj.actionType);

        if (defMembership) {
            fetchData(typeOptionObj.actionType, typeOptionObj, selectedProductObj, thirdOptionObj, text, id, customDateValue);
            updateUrl(typeOptionObj, selectedProductObj, thirdOptionObj, text, id, customDateValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, allPlanGroups, state.page, defMembership]);

    const updateUrl = (type, product, period, text, bsId, customDateValue) => {
        const params = new URLSearchParams();

        if (type) params.set('type', type.alias);

        if (product && product.name) {
            params.set('product', product.id);
        }

        const todayDate = new Date().toISOString().split('T')[0];
        let startDate;

        if (period && period.code === 'custom' && customDateValue) {
            startDate = customDateValue;
        } else if (period) {
            switch (period.code) {
                case 'today':
                    startDate = todayDate;
                    break;
                case 'last_week':
                    startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_month':
                    startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_3_months':
                    startDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_6_months':
                    startDate = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_12_months':
                    startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                default:
                    startDate = todayDate;
            }
        } else {
            startDate = todayDate;
        }

        params.set('startDate', startDate);
        params.set('endDate', todayDate);

        if (text) {
            params.set('searchText', text)
        } else if (bsId) {
            params.set('bcId', bsId)
        };
        setSearchParams(params, { replace: true });
    };

    const fetchData = (actionType, type, product, third, text, bsId, customDateValue) => {
        const todayDate = new Date().toISOString().split('T')[0];
        let startDate;

        if (third && third.code) {
            switch (third.code) {
                case 'today':
                    startDate = todayDate;
                    break;
                case 'last_week':
                    startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_month':
                    startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_3_months':
                    startDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_6_months':
                    startDate = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                case 'last_12_months':
                    startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
                    break;
                default:
                    startDate = todayDate;
            }
        } else {
            startDate = todayDate;
        }

        let queryParams = `?startDate=${startDate}&endDate=${todayDate}`;

        if (product) {
            const productId = product.id;
            if (productId) {
                queryParams += `&groupId=${productId}`;
            }
        }

        if (text) {
            queryParams += `&searchText=${encodeURIComponent(text)}`;
        }
        else if (bsId) {
            queryParams += `&bcId=${encodeURIComponent(bsId)}`;
        }

        const collectedPaginationParams = {
            page: state.page,
            size: state.size,
            sort: "receivedAt,desc"
        };
        const paginationParams = {
            page: state.page,
            size: state.size,
            sort: "scheduledFor,desc"
        };

        const id = defMembership?.business?.id;
        switch (actionType) {
            case "FETCH_COLLECTED":
                getCollectedPayments(id, queryParams, collectedPaginationParams);
                break;
            case "FETCH_MISSED":
                getMissedPayments(id, queryParams, paginationParams);
                break;
            default:
                getExternalPayments(id, queryParams, paginationParams);
                break;
        }
    };

    const handleTypeChange = (e) => {
        const selectedOption = e.value;
        setTypeSelection(selectedOption);
        setCurrentActionType(selectedOption.actionType);
        updateUrl(selectedOption, productSelection, periodSelection, searchText,bcId, customDate);
        fetchData(selectedOption.actionType, selectedOption, productSelection, periodSelection, searchText, bcId,customDate);
    };

    const handleSecondChange = (e) => {
        const selectedOption = e.value;
        setProductSelection(selectedOption);
        updateUrl(typeSelection, selectedOption, periodSelection, searchText, bcId,customDate);
        fetchData(currentActionType, typeSelection, selectedOption, periodSelection, searchText, bcId, customDate);
    };

    const handlePeriodChange = (selectedPeriod) => {
        const periodValue = selectedPeriod.value;
        setPeriodSelection(periodValue);

        if (periodValue.code === 'custom') {
            setShowCustomDate(true);
            setCalendarVisible(false);
        } else {
            setShowCustomDate(false);
            setCalendarVisible(false);
            setCalendarDates(null);
            setCustomDate('');

            updateUrl(
                typeSelection,
                productSelection,
                periodValue,
                searchText,
                bcId,
                ''
            );
            fetchData(
                currentActionType,
                typeSelection,
                productSelection,
                periodValue,
                searchText,
                bcId,
                ''
            );
        }
    };

    const handleCalendarSelect = (e) => {
        setCalendarDates(e.value);

        if (Array.isArray(e.value) && e.value.length === 2 && e.value[0] && e.value[1]) {
            const startDate = e.value[0].toISOString().split('T')[0];
            const endDate = e.value[1].toISOString().split('T')[0];

            const displayValue = `${startDate} - ${endDate}`;
            setCustomDate(displayValue);
            setCalendarVisible(false);

            updateUrlWithCustomDates(startDate, endDate);
            fetchDataWithCustomDates(startDate, endDate);
        }
    };

    const updateUrlWithCustomDates = (startDate, endDate) => {
        const params = new URLSearchParams();

        if (typeSelection) params.set('type', typeSelection.alias);
        if (productSelection) params.set('product', productSelection.name);

        params.set('startDate', startDate);
        params.set('endDate', endDate);

        if (searchText) {
            params.set('searchText', searchText)
        } else if (bcId) {
            params.set('bcId', bcId)
        };

        setSearchParams(params, { replaced: true })
    };

    const fetchDataWithCustomDates = (startDate, endDate) => {
        let queryParams = `?startDate=${startDate}&endDate=${endDate}`;

        if (productSelection) {
            const result = typeof productSelection === 'number' ? productSelection : productSelection.id;
            queryParams += `&groupId=${result}`;
        }

        if (searchText) {
            queryParams += `&searchText=${encodeURIComponent(searchText)}`;
        } else if (bcId) {
            queryParams += `&bcId=${encodeURIComponent(bcId)}`;
        }

        const collectedPaginationParams = {
            page: state.page,
            size: state.size,
            sort: "receivedAt,desc"
        };

        const paginationParams = {
            page: state.page,
            size: state.size,
            sort: "scheduledFor,desc"
        };

        const id = defMembership?.business?.id;

        switch (currentActionType) {
            case "FETCH_COLLECTED":
                getCollectedPayments(id, queryParams, collectedPaginationParams);
                break;
            case "FETCH_MISSED":
                getMissedPayments(id, queryParams, paginationParams);
                break;
            default:
                getExternalPayments(id, queryParams, paginationParams);
                break;
        }
    };

    const handleSearchChange = (e) => {
        const value = e.target?.value;
        setSearchText(value);
        updateUrl(typeSelection, productSelection, periodSelection, value, customDate);
        fetchData(currentActionType, typeSelection, productSelection, periodSelection, value, customDate);
    };

    const formatDate = date => {
        return moment(date).format('DD MMM YYYY')
    }

    function getTimeFromDateString(dateString) {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    function convertDate(inputDate) {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
    }

    const statusTemplate = (rowData, e) => {
        return rowData && <span className={`product-badge status-${rowData ? rowData.toLowerCase() : ""}`}>{rowData}</span>;
    };

    const markActionTemplate = (rowData) => {
        if (rowData) {
            return (rowData.subscriptionStatus === "ACTIVE" ? < Button
                label={t("subscriber.paid")}
                style={{ color: (defMembership.role !== "VIEWER") ? "#008dd5" : '#6c757d' }}
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    if (defMembership.role !== "VIEWER") {
                        setDisplayBasic(true)
                        setState((state) => ({
                            ...state,
                            id: rowData.id,
                            flex: rowData.flex,
                            scheduledDate: rowData.scheduledDate
                        }));
                    }
                }}
            /> : "")
        }
    }

    const subscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return typeSelection.code !== "MP" ? <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <div className="subscriptionBodyTemplate">
                    {
                        rowData.planName ?
                            <p className="detail-navigation" id="tierName"
                                onClick={() => {
                                    navigate(`/products/plan/detail/${rowData.planId}`)
                                }} >  {rowData.planName} </p> : null
                    }
                    {
                        rowData.planPeriod ?
                            <p className="plan-group-text" >
                                {t(`product.${rowData.planPeriod.toLowerCase()}`)}</p> : null
                    }
                    {
                        rowData.busCustomerName ?
                            <p className="ref-text" id='tierSub' style={{ marginBottom: "3px" }}>
                                {rowData.busCustomerName}</p>
                            : rowData.customerName ? <p className="ref-text" id='tierSub' style={{ marginBottom: "3px" }}>
                                {rowData.customerName}</p> : null
                    }
                    {
                        rowData.planName ?
                            <span className="ref-text " id='tierSub' > {rowData.phoneNo} </span> : null
                    }
                    {
                        rowData.groupName ?
                            <p className="groupName" id='tierSub' >{rowData.groupName} </p> : null
                    }
                </div >
            </div > :
                <div>
                    {
                        rowData.scheduledDate ? <p className="detail-navigatio" id="scheduleId">{convertDate(rowData.scheduledDate)}</p> : null
                    }
                </div>
        }
    }
    const paymentIdBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {typeSelection.code === "CP" ?
                    <div>{
                        rowData.reference ?
                            <p className="detail-navigation paymentID" id="paymentId"
                                onClick={() => navigate(`/payments/detail/${rowData.id}`)}> {rowData.reference}</p> : "--"
                    }</div>
                    :
                    typeSelection.code === "MP" ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                            <div className="subscriptionBodyTemplate">
                                {
                                    rowData.planName ?
                                        <p className="detail-navigation" id="tierName"
                                            onClick={() => {
                                                navigate(`/products/plan/detail/${rowData.planId}`)
                                            }}>{rowData.planName} </p> : null
                                }
                                {
                                    rowData.planPeriod ? <p className="plan-group-text">{t(`product.${rowData.planPeriod.toLowerCase()}`)}</p> : null
                                }
                                {
                                    rowData.customerName !== null ? <p className="ref-text customerName-txt" id='tierSub'> {rowData.customerName}</p> : null
                                }
                                {
                                    rowData.phoneNo ? <span className="ref-text " id='tierSub'> {rowData.phoneNo}</span> : null
                                }
                                {
                                    rowData.groupName ? <p className="groupName" id='tierSub'> {rowData.groupName}</p> : null
                                }
                            </div >
                        </div > :
                        <div>
                            {
                                rowData.id ? <p className="detail-navigatio" id="transactionId"> {rowData.id}</p> : null
                            }
                        </div>
                }
            </div>
        }
    }

    const occuredBodyTemplate = (rowData, e) => {
        if (rowData) {
            return <div>
                {typeSelection.code === "CP" ?
                    <div>{
                        rowData.receivedAt ?
                            <div className="detail-navigatio" id="occured">
                                <span >{formatDate(rowData.receivedAt)}</span>
                                <p style={{ marginTop: "0.5rem", fontSize: "11px", color: "#8f9395" }}>{getTimeFromDateString(rowData.receivedAt)}</p>
                            </div> : '--'
                    }</div>
                    :
                    typeSelection.code === "MP" ?
                        <div>{
                            rowData.status ? statusTemplate(rowData.status, e) : "--"
                        }</div> :
                        <div>{
                            rowData.externallyReceivedOn ? <div className="detail-navigatio" id="occured" >
                                <span >{formatDate(rowData.externallyReceivedOn)}</span>
                                <p style={{ marginTop: "0.5rem", fontSize: "11px", color: "#8f9395" }}>{getTimeFromDateString(rowData.externallyReceivedOn)}</p>
                            </div> : '--'
                        }</div>}
            </div>
        }
    }

    const amountBodyTemplate = (rowData, e) => {
        if (rowData) {
            return <div>
                {typeSelection.code === "CP" ?
                    <div>{
                        rowData.amount ?
                            <div className="col_amounts" style={{ flexDirection: "column" }}>
                                <span className="firstPayment">{defMembership.business.currency} {rowData.amount.toFixed(2)}</span>
                                {
                                    rowData.setupFee !== null ? <div>
                                        <div style={{ display: "flex" }}>
                                            <p className="mb-1">
                                                <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>{t("subscriber.amount")}:</span>
                                                <span style={{ color: "grey", fontWeight: "bold", fontSize: "12px" }}>{" "}{defMembership.business.currency}{" "}
                                                    {rowData.originalAmount.toFixed(2)}</span>
                                            </p>
                                        </div>
                                        <p className="setup_fee_div" id="setup-m-b">
                                            <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                                            <span className="setup_fee_value">{defMembership.business.currency}{" "}{rowData.setupFee.toFixed(2)}</span>
                                        </p>
                                    </div> : ""
                                }
                                <div className='flex'>
                                    <p className='payment-style'>{rowData.paymentMode}</p>
                                    <p className='payment-style mr-2 ml-2'>|</p>
                                    <p className='payment-style'>{rowData.paymentModeName}</p>
                                </div>
                                <span className='payment-style'>{rowData.phoneNo}</span>
                            </div>
                            : "--"
                    }</div>
                    :
                    typeSelection.code === "MP" ?
                        <div>{
                            rowData.subscriptionStatus ? statusTemplate(rowData.subscriptionStatus, e) : '--'
                        }</div> :
                        <div>
                            {
                                rowData.flex === true ?
                                    <div>
                                        <span style={{ display: "block", marginBottom: "0.5rem" }}>{t("products.flexible_amount")}</span>
                                        {rowData.amount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.amount.toFixed(2)}</span>
                                            : "--"}
                                    </div>
                                    :
                                    <div>
                                        {rowData.amount ? <p className="transactions_data"> {defMembership.business.country.currency} {rowData.amount.toFixed(2)}</p> : '--'}
                                    </div>
                            }
                        </div>
                }
            </div>
        }

    }

    const feesBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {typeSelection.code === "CP" ?
                    <div>{
                        rowData.fees ?
                            <p className="detail-navigatio feePayment" id="fees" >
                                {defMembership.business.currency} {rowData.fees.toFixed(2)}
                            </p> : null
                    }</div>
                    :
                    typeSelection.code === "MP" ?
                        <div>{
                            rowData.flex === true ? t("products.flexible_amount")
                                : rowData.amount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.amount.toFixed(2)}</span>
                                    : '--'}</div> :
                        <div>{rowData.extReceivedNotes ?
                            <p className="detail-navigatio" id="fees"> {rowData.extReceivedNotes} </p> : '--'
                        }</div>}
            </div>
        }
    }

    const redeemableBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {typeSelection.code === "CP" ?
                    <div>{
                        rowData.redeemable ?
                            <p className="detail-navigatio revenue" id="redeemable">
                                {defMembership.business.currency} {rowData.redeemable.toFixed(2)}
                            </p> : null
                    }</div>
                    : typeSelection.code === 'MP' && <div>
                        <div>{
                            rowData.subscriptionStatus === 'ACTIVE' ?
                                markActionTemplate(rowData)
                                : null
                        }</div>
                    </div>
                }
            </div>
        }
    }

    let columns = [
        {
            field: "reference",
            header: typeSelection.code === "CP" ? t("payments.payment_id") : typeSelection.code === "MP" ? t("payments.subscription_info") : t("payments.transaction_id"),
            className: typeSelection.code === "CP" ? "payment-id" : typeSelection.code === "MP" ? "tier-info" : "payment-id",
            body: paymentIdBodyTemplate,
        },
        {
            field: "customerNum",
            header: typeSelection.code === "CP" ? t("payments.subscription_info") : typeSelection.code === "MP" ? t("payments.schedule_for") : t("payments.subscription_info"),
            className: typeSelection.code !== "MP" ? "subscription-info" : "schedule-for",
            body: subscriptionBodyTemplate,
        },
        {
            field: "receivedAt",
            header: typeSelection.code === "CP" ? t("payments.occurred_on") : typeSelection.code === "MP" ? t("payments.schedule_status") : t("payments.occurred_on"),
            className: typeSelection.code === "CP" ? "occurred-on" : typeSelection.code === "MP" ? "schedule-status-payments" : "occurred-on",
            body: occuredBodyTemplate,
            sort: true,
        },
        {
            field: "amount",
            header: typeSelection.code === "CP" ? t("payments.collect") : typeSelection.code === "MP" ? t("payments.subscription_status") : t("payments.amount"),
            className: typeSelection.code === "CP" ? "collected-info" : typeSelection.code === "MP" ? "subscription-status" : "collected",
            body: amountBodyTemplate,
            sort: true,
        },
        {
            field: "fees",
            header: typeSelection.code === "CP" ? t("payments.service_fees") : typeSelection.code === "MP" ? t("payments.amount") : t("payments.note"),
            className: typeSelection.code === "CP" ? "fees" : typeSelection.code === "MP" ? "collected" : "note",
            body: feesBodyTemplate,
            sortable: typeSelection.code !== "ER" && true,
        },

    ];

    // Conditionally add the last object based on typeSelection.code
    if (typeSelection.code !== "ER") {
        columns.push({
            field: "redeemable",
            className: typeSelection.code === "MP" || typeSelection.code === "CP" ? "revenue" : 'no-revenue',
            header: typeSelection.code === 'CP' && t("payments.revenue"),
            body: redeemableBodyTemplate,
            sort: typeSelection.code === 'CP' && true,
        },);
    }

    const today = new Date();
    const todayDate = moment(today).format().split("T").shift()
    const endDates = moment(defMembership.joinedOn).format().split("T").shift()
    const totalRecords = typeSelection.code === "CP" ?
        collectedPayments?.totalElements : typeSelection.code === "MP" ? missPayments?.totalElements
            : externalPayments?.totalElements

    const onPageChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        }
        setState((state) => ({ ...state, first: e.first, page: e.page }));
    }

    return (
        <>
            <MarkAsPaid displayDialog={displayBasic} onHide={() => { setDisplayBasic(false) }}
                scheduleId={state.id}
                isFlexTrue={state.flex}
                scheduledDate={state.scheduledDate}
                callbackFunctions={() => {
                    getCollectedPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { sort: "receivedAt,desc" });
                    getMissedPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { sort: "scheduledDate,desc" });
                    getExternalPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { sort: "scheduledDate,desc" });
                }} />
            <ActionBar typeSelection={typeSelection} setSelectedType={handleTypeChange} dropdownItems={dropdownItems} selectedCustomer={searchText}
                setSelectedCustomer={handleSearchChange} selectedOption={productSelection} summaryOptions={state.allProductsObject}
                setSelectedOption={handleSecondChange} period={periodSelection} periods={periods} setPeriod={handlePeriodChange} total={typeSelection.code === "CP" ? collectedPayments?.totalElements
                    : typeSelection.code === "MP" ? missPayments?.totalElements : externalPayments?.totalElements} showCustomDate={showCustomDate} showSubscriberName={showSubscriberName} setShowSubscriberName={setShowSubscriberName}
                calendarDates={calendarDates} handleCalendarSelect={handleCalendarSelect} calendarVisible={calendarVisible} />

            <CustomDataTable columns={columns}
                loading={loading}
                value={typeSelection.code === "CP" ? collectedPayments?.content : typeSelection.code === "MP" ? missPayments?.content : externalPayments?.content}>
            </CustomDataTable>

            <Paginator rows={totalRecords?.length === 0 ? 0 : state.size} totalRecords={totalRecords} first={state.first} onPageChange={onPageChange} ></Paginator>
        </>
    );
}

export default Payments;