import React from 'react'
import RoleAccess from '../../Components/RoleAccess';
import "../../Styles/pages/product.scss"
import moment from "moment";
import { FullWidthCardTemplate, MediumCardTemplate, SmallCardTemplate } from './ProductSummaryInsights';

const PlanDetailCards = (props) => {
    const { t, state, defMembership, setNameChangeVisible, setVisible, setVisibleInvite,
        planShare, togglePriceChange, toggleMoveSubscriber, setState } = props

    const moneyTemplate = (amount = 0) => (
        <div className='flex'>
            <span className="count-curren">{defMembership.business.currency}</span>
            <span className="amt-fig">{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount?.toFixed(2))}</span>
        </div>)

    const createdDate = state.plan.createdDate ? moment(state.plan.createdDate).format("MMMM DD YYYY") : "";
    const lastModifiedDate = state.plan.lastModifiedDate ? moment(state.plan.lastModifiedDate).format("MMMM DD YYYY") : "";

    const sideMenu = [
        {
            label: t('products.share_plan'),
            icon: "pi-share-alt",
            onclick: () => { planShare(); }
        }, {
            label: t('products.edit_plan_info'),
            icon: "pi-pencil",
            onclick: () => { setNameChangeVisible(state.plan) }
        }, {
            label: t('products.archive_plan'),
            icon: "pi-lock",
            onclick: () => { setVisible(true) }
        }, {
            label: t('products.change_plan_price'),
            icon: "pi-money-bill",
            onclick: () => { togglePriceChange(state.plan) },
            show: state.plan.policy === "TIER" && !state.plan.flex
        }
    ]
    const subsActions = [
        {
            label: t('product.migrate'),
            icon: "pi-user-minus",
            onclick: () => {
                toggleMoveSubscriber(); setState((state) => {
                    return { ...state, sourceTier: { data: state.plan } }
                })
            }
        }, {
            label: t('subscriber.invite_subscriber'),
            icon: "pi-user-plus",
            onclick: () => {
                setVisibleInvite(true)
            }, allRoles: true
        },
    ]

    const mainCardPlan = [
        {
            page: "plan-detail",
            t,
            defMembership,
            planDetState: state,
            createdDate,
            lastModifiedDate,

        }
    ]

    const details = [
        {
            type: "payments-7",
            title: `${t("products.payments")}: ${t("products.last-7")}`,
            cardInfo: t("products.payments_duration", { duration: t("products.last-7") }),

            collectedSevenDay: t("products.collected"),
            collectedSevenDayValue: moneyTemplate(state.performance.sevenDayCollected),

            revenueSevenDay: props.t("products.revenue"),
            revenueSevenDayValue: moneyTemplate(state.performance.sevenDayRevenue),

            missedSevenDay: t("products.missed"),
            missedSevenDayValue: moneyTemplate(state.performance.sevenDayMissed),

            pendingSevenDay: t("products.upcoming"),
            pendingSevenDayValue: moneyTemplate(state.performance.sevenDayPending)
        },
        {
            type: "payments-30",
            title: `${t("products.payments")}: ${t("products.last-30")}`,
            cardInfo: t("products.payments_duration", { duration: t("products.last-30") }),

            collectedthirtyDay: t("products.collected"),
            collectedthirtyDayValue: moneyTemplate(state.performance.thirtyDayCollected),

            revenuethirtyDay: props.t("products.revenue"),
            revenuethirtyDayValue: moneyTemplate(state.performance.thirtyDayRevenue),

            missedthirtyDay: t("products.missed"),
            missedthirtyDayValue: moneyTemplate(state.performance.thirtyDayMissed),

            pendingthirtyDay: t("products.upcoming"),
            pendingthirtyDayValue: moneyTemplate(state.performance.thirtyDayPending),
        },
        {
            type: "lifetime",
            title: `${t("products.payments")}: ${t("products.lifetime")}`,
            cardInfo: t("products.payments_lifetime"),

            collectedLifetime: t("products.collected"),
            collectedLifetimeValue: moneyTemplate(state.performance.collected),

            revenueLifetime: props.t("products.revenue"),
            revenueLifetimeValue: moneyTemplate(state.performance.revenue),

            missedLifetime: t("products.missed"),
            missedLifetimeValue: moneyTemplate(state.performance.missed),

            pendingLifetime: t("products.upcoming"),
            pendingLifetimeValue: moneyTemplate(state.performance.pending)
        }
    ]

    const subscriptionContent = [
        {
            title: t("new"),
            subDesc: t(state.performance.newSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.newSubscriptionCount,
            cardInfo: t("products.tooltip_subs", { sub: t("new"), plan: state.plan.name }),
            className: "summary-new",
        },
        {
            title: t("products.Paused"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Paused"), plan: state.plan.name }),
            subDesc: t(state.performance.pausedSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.pausedSubscriptionCount,
            className: 'summary-paused'
        },
        {
            title: t("products.Cancelled"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Cancelled"), plan: state.plan.name }),
            subDesc: t(state.performance.cancelledSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.cancelledSubscriptionCount,
            className: 'summary-cancelled'
        },
        {
            title: t("products.ACTIVE"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.ACTIVE"), plan: state.plan.name }),
            subDesc: t(state.performance.activeSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.activeSubscriptionCount,
            className: 'summary-active'
        },
        {
            title: t("products.Completed"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Completed"), plan: state.plan.name }),
            subDesc: t(state.performance.completedSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.completedSubscriptionCount,
            className: 'summary-completed'
        },
        {
            title: t("products.Aborted"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Aborted"), plan: state.plan.name }),
            subDesc: t(state.performance.abortedSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.abortedSubscriptionCount,
            className: 'summary-aborted'
        },
    ]

    return (
        <div>
            <div className="grid nested-grid">
                <div className='col-9'>
                    <div className="grid" id="">
                        <FullWidthCardTemplate summary={mainCardPlan} />
                    </div>

                    <p className='insights'>{t("products.summary-insights")}</p>

                    <div className='grid' id='plandet-subscriptions'>
                        <SmallCardTemplate summary={subscriptionContent} />
                    </div>

                    <div className='grid' id='plan-cards-insights'>
                        <MediumCardTemplate summary={details} />
                    </div>

                </div>

                <div className="col-3">
                    <RoleAccess roles={["OWNER", "ADMIN"]}>
                        {(state.plan.status !== "ARCHIVED" && state.plan.group?.status !== "ARCHIVED") &&
                            <div id="product-detail-side-menu">
                                <p className="action-titles">{t("plan.quick_action")}</p>
                                {sideMenu.map(({ label, icon, onclick, show = true }, i) => (
                                    <div key={i}> {
                                        show && <div className="side-menu-content"  >
                                            <span onClick={onclick}>
                                                <i className={"pi " + icon} />
                                                <span> {label}</span>
                                            </span>
                                        </div>
                                    }
                                    </div>
                                ))}
                                <hr className="side-menu-hr" />
                                <p className="action-titles">{t("plan.subs_action")}</p>
                                {subsActions.map(({ label, icon, onclick, show = true }, i) => (<div>
                                    <div key={i}> {
                                        show && <div className="side-menu-content">
                                            <span onClick={onclick}>
                                                <i className={"pi " + icon} />
                                                <span> {label}</span>
                                            </span>
                                        </div>
                                    }
                                    </div>
                                </div>
                                ))}
                            </div>}
                    </RoleAccess>
                </div>
            </div >
        </div>
    )
}
export default PlanDetailCards;