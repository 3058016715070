import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from "primereact/toast";
import "../Styles/components/Share.scss";
import RoleAccess from "../Components/RoleAccess";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "./../Redux/transition/plan-group/plan-group.reducer";

const SharePlan = (props) => {
  const enablingReport = useSelector((state) => state.planGroups.enabling);
  const successEnabling = useSelector((state) => state.planGroups.planReport);
  const errorEnabling = useSelector((state) => state.planGroups.errorEnabling);
  const business = useSelector((state) => state.businesses.business);

  const dispatch = useDispatch();
  const enablePlanReport = useCallback((planGroupId, report) => { dispatch(PlanGroupActions.enablePlanReportRequest(planGroupId, report)) }, [dispatch]);
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch]);

  const { t } = useTranslation("common");
  const { setSharePlanDialog, sharedPlan: { plan, shortUrl } } = props;
  const toast = useRef(null);

  // eslint-disable-next-line
  const [report, setReport] = useState("");
  // eslint-disable-next-line
  const [successReportable, setSuccessReportable] = useState(false);
  // eslint-disable-next-line
  const [successReportCode, setSuccessReportCode] = useState("");
  const [showToast, setShowToast] = useState(false);
 // eslint-disable-next-line
  const [reportPin, setReportPin] = useState("");
  const [enabling, setEnabling] = useState(false);
  const [initialState, setInitialState] = useState(null);
  const [pinError, setPinError] = useState("");
  const [state, setState] = useState({
          check1: false,
          toggle: false,
          check2: false,
          pin: "",
        });
      
        const [isEditingPin, setIsEditingPin] = useState(false);
        const [originalPin, setOriginalPin] = useState("");
  
  

  useEffect(() => {
    if (!enablingReport && successEnabling && enabling) {
      toast.current.show({
        life: 3000,
        severity: "success",
        summary: t("share.saved"),
        detail: t("share.save_message"),
      });
    }
    if (!enablingReport && errorEnabling && enabling) {
      toast.current.show({
        life: 3000,
        severity: "error",
        summary: t("share.saved"),
        detail: t("share.save_message"),
      });
    }
    // eslint-disable-next-line
  }, [enabling, enablingReport, successEnabling]);


  const showCopyPin = () => {
    const propsPin = <span className="styleCode">{props.reportCode}</span>
    if ((showToast && state.pin !== "")) {
      return <div><span>{t("share.pin")}</span> {state.pin}</div>;
    }
    
    if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
      return <div><span>{t("share.pin")}</span> {propsPin}</div>;
    } else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
      return;
    }
  };

  const textDisplay = () => {
    if (!showToast && props.reportable) { return t("share.report", { plan }) };
    if (!showToast && !props.reportable) { return t("share.signup", { plan }) };
    if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
      return t("share.report", { plan })
    } else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
      return t("share.signup", { plan })
    }
    if (showToast && successReportable) { return t("share.report", { plan }) }
    else if (showToast && !successReportable) { return t("share.signup", { plan }) }
    else if (showToast && successReportable && successReportCode !== "") { return t("share.report", { plan }) }
    else if (showToast && successReportable && successReportCode !== null) { return t("share.report", { plan }) }
  };

  const copyText = !showToast && report && (props.reportCode || reportPin) ? `${t("share.report", { plan })} ${shortUrl} ${t(
    "share.pin")} ${reportPin}` : !showToast && report && !props.reportCode ? `${t("share.report", { plan })} ${t("share.url", { shortUrl })}`
    : `${t("share.signup", { plan })} ${t("share.url", { shortUrl })}`;

  const copyTextSave =
    showToast && report && successReportCode !== "" ? `${t("share.report", { plan })} ${shortUrl} ${t("share.pin")} ${reportPin}`
      : showToast && report && successReportCode === "" ? `${t("share.report", { plan })} ${t("share.url", { shortUrl })}`
        : `${t("share.signup", { plan })} ${t("share.url", { shortUrl })}`;

      
        useEffect(() => {
          if (props.visible) {
            const fetchInitialState = async () => {
              const response = {
                check1: props.reportable,
                toggle: props.hideAmount,
                check2: props.reportable && props.reportCode !== "", 
                pin: props.reportCode,
              };
              setInitialState(response);
              setState(response);
              setOriginalPin(response.pin);
            };
        
            fetchInitialState();
          }
           // eslint-disable-next-line
        }, [props.visible]);

        const handleCheck1Change = (e) => {
          const newCheck1 = e.checked;
        
          setState((prevState) => ({
            check1: newCheck1,
            toggle: newCheck1 ? initialState.toggle : false,
            check2: newCheck1 ? (initialState.pin ? true : false) : false, 
            pin: newCheck1 ? initialState.pin : "", 
          }));
        
          if (!newCheck1) {
            setIsEditingPin(false);
          }
        };
        

        const handlePinChange = (e) => {
          const newPin = e.target.value;
        
          setState((prevState) => ({
            ...prevState,
            pin: newPin,
            check2: prevState.check1 && newPin !== "",
          }));
        
          if (newPin.length > 0 && newPin.length < 5) {
            setPinError("PIN must be 5 characters");
          } else {
            setPinError("");
          }
        };
             
      
        const handleToggleChange = (e) => {
          setState((prevState) => ({
            ...prevState,
            toggle: e.value,
          }));
        };
 
        const handleCheck2Change = (e) => {
          setState((prevState) => ({
            ...prevState,
            check2: e.checked,
            pin: e.checked ? (initialState.pin || prevState.pin) : "", 
          }));
        
          
          if (e.checked && !initialState.pin) {
            setIsEditingPin(true); 
          } else {
            setIsEditingPin(false); 
          }
        };
      
       
        const isChanged =
          initialState &&
          (state.check1 !== initialState.check1 ||
            state.toggle !== initialState.toggle ||
            state.check2 !== initialState.check2 ||
            state.pin !== initialState.pin);
      
        const isPinValid = state.check2 ? state.pin?.length === 5 : true;
        const shouldShowSave = isChanged && isPinValid && (!state.check2 || (state.check2 && state.pin?.trim() !== ""));
      
        const handleSave = () => {
          console.log("Saving new state:", state);
          enablePlanReport(props.sharedPlan.planGroupId, {
            reportable: state.check1,
            hideAmount: state.toggle,
            reportCode: state.pin,
          });
          setEnabling(true)
          
          // This should be for when save is successful, update initial state
          setInitialState(state);
          setOriginalPin(state.pin); 
          setIsEditingPin(false); 
        };
        

  const hasPin = reportPin?.toString().length === 5;
  return (
    <>
      <Toast
        ref={toast}
        onRemove={(message) => {
          if (message.severity === "success") {
            getActivePlanGroups(business.id);
            setEnabling(false)
            setShowToast(true)
          }
        }}
      />
      <Dialog
        header={t("product.share_url")}
        headerClassName="planShareHeader"
        className="planShare share_dialog"
        id="share_dialog"
        visible={props.visible}
        onHide={() => {
          setSharePlanDialog(false);
          props.setDialogClosed(true)
          setShowToast(false)
          setIsEditingPin(false)
        }}
      >
        <div className="title_btn">
          <p className="share-title">{t("product.share_title")}</p>
          <Button
            label={state.copied ? t("share.copied") : t("share.copy")}
            className="copy_btn"
            onClick={() => {
              !showToast ? navigator.clipboard.writeText(copyText) : navigator.clipboard.writeText(copyTextSave);
              setState((state) => ({
                ...state,
                copied: true,
                change_pin: true,
              }));
            }}
          />
        </div>
        <div className="productShare-box">
          <div className="text-dis">
            <span className="mb-1 font-bold">{textDisplay()}</span>
            <span>{t("share.url", { shortUrl })}</span>
            <br />
            <span>{showCopyPin()}</span>
          </div>
        </div>
        <RoleAccess roles={["OWNER", "ADMIN"]}>
          <div className="grid mt-4 mb-4">
            <span className="col-4">
              <Checkbox
                inputId="isReport"
                checked={state.check1} onChange={handleCheck1Change}
              ></Checkbox>
              <label htmlFor="isReport" className="p-checkbox-label">{t("share.enable_report")} </label>
              <span className="p-checkbox-hint hint-style">{t("share.enable_report_hint")} </span>
            </span>
            <div className="col-8">
              <span className="main-hint">{t("share.enable_report_hint_main")} </span>
            </div>
            <br />
          </div>

          <div className="grid mb-4">
            <span className="col-4">
              <InputSwitch inputId="toggle" checked={state.toggle} onChange={handleToggleChange} 
              disabled={!state.check1}   
              >
              </InputSwitch>
              <label htmlFor="toggle" className="ml-3">{t("share.toggle_label")}</label>
            </span>
            <span className="col-8">
              <span className="main-hint">{t("share.toggle_hint_main")} </span>
            </span>
          </div>

          <div className="grid">
            <span className="col-4">
              <Checkbox
                inputId="addPin" 
                checked={state.check2} onChange={handleCheck2Change} disabled={!state.check1}
                 >
              </Checkbox>
              <label htmlFor="addPin" className="p-checkbox-label"> {t("share.add_pin")}  </label>
            </span>
            <div className="col-8">
              <span className="main-hint">{t("share.add_pin_hint_main")}</span>
            </div>
          </div>
          <div className="flex justify-content-between align-items-end pin-box">
            <div className="field flex flex-column md:col-2 mb-0">
            {state.check2 && (
          <div className="flex flex-column gap-2">
        
                  <InputText
                    id="pin"
                    value={state.pin}
                    onChange={handlePinChange}
                    placeholder="12345"
                    disabled={!isEditingPin}
                    className={`p-inputtext ${!isEditingPin && originalPin ? "borderless" : ""}`}
                    maxLength={5}
                  />

            {pinError && <small className="p-error">{pinError}</small>}
          
            {originalPin && (
              <Button
                label={isEditingPin ? "Cancel" : "Change PIN"}
                className="p-button-text"
                onClick={() => setIsEditingPin((prev) => !prev)}
              />
            )}
          </div>
        )}
        </div>

{shouldShowSave && (
          <Button
          label={t("share.save")}
            onClick={handleSave}
            loading={enablingReport}
            className="save_btn"
            />
        )}
{enablingReport && (
          <Button
          label={t("share.save")}
            loading={enablingReport}
            className="save_btn"
            />
        )}
          
        
              {report && reportPin && !hasPin && (<small className="p-error">{t("share.pin_err")}</small>)}
                        
          </div>
        </RoleAccess>
      </Dialog>
    </>
  );
};
export default SharePlan;
