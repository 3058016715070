import React from 'react'
import { Tooltip } from 'primereact/tooltip';
import moment from "moment";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';

const LargeCard = ({ page, name, phoneNo, refTitle, cusNumTitle,
      email, cusNum, reference, props, updatedOn,
      createdOn, planDetState, t, defMembership, createdDate,
      lastModifiedDate, productAccountBalances, withdrawalState, subscribers, subscriptionLastModifiedOnDate, subscriptionCreatedOnDate }) => {
      return (
            <div className='col-12'>
                  <Card className='p-2' id='large-card-container' >
                        {page === "subscriber-detail" && (<div className='grid p-2'>
                              <div className='col-6 flex flex-column'>
                                    <p className='name-label mb-0'>{name}</p>
                                    <p className='contact-label-phoneNo mb-0'>{phoneNo}</p>
                                    <p className='contact-label-email'>{email}</p>
                              </div>
                              <div className='col-6 flex flex-column'>
                                    <div className='flex'><p className='sub-label mb-0'>{refTitle}:</p><p className='ref-label pl-1'>{cusNum}</p></div>
                                    <div className='flex'><p className='sub-label mb-0'>{cusNumTitle}:</p> <p className='customerNum-label pl-1'>{reference}</p></div>
                              </div>

                        </div>)}

                        {page === "product-detail" && (<div className="product-main-card">
                              <div className="col-7">
                                    <div className="grid">
                                          <div className="col-3">
                                                {props.state.planGroup.exclusive ? <p className="exclusive-tag">{props.t("products.EXCLUSIVE")}</p> :
                                                      <p className="non-exclusive-tag">{props.t("products.NON-EXCLUSIVE")}</p>}
                                          </div>
                                          <div className="col-9">
                                                <div className="product-info-dates">
                                                      <span className={`planCard-badge product-status status-${props.state.planGroup.status?.toLowerCase()}`}>{props.state.planGroup.status}</span>
                                                      {createdOn === updatedOn && <div> <span className="planCard-date">
                                                            {props.t("product.created_on")}{":"}</span> <span className="planModifiedDate">{createdOn}</span>
                                                      </div>}
                                                      {createdOn !== updatedOn && <div><span className="planCard-date">
                                                            {props.t("product.updated_on")}{":"}</span> <span className="planModifiedDate">{updatedOn}</span>
                                                      </div>}
                                                </div>
                                          </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                          {props.state.planGroup.status === "ARCHIVED" && <div>
                                                <i className="pi pi-lock" id="archived_icon"></i>
                                          </div>}
                                          <div className="product-name-desc" >
                                                <span className="productCard-name">{props.state.planGroup.name}</span>
                                                <span className="planCard-dec">{props.state.planGroup.description}</span>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-5 balance-side-div">
                                    <div className="balance-main-div">
                                          <span id="balance_label">{props.t("Balance")}{":"}</span>&nbsp;
                                          <span className="balance-value">{props.defMembership.business?.currency}
                                                {" "}{props.state.performance.balance?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </div>
                                    <div className="available_pending">
                                          <div className="available-main-div">
                                                <span id="available_label">{props.t("product.available")}{":"}</span>&nbsp;
                                                <span className="detail-card-value">{props.defMembership.business?.currency}{" "}{props.state.performance.available?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                          </div>
                                          <div className="pending-main-div">
                                                <span id="pending_label">{props.t("products.pending")}{":"}</span>&nbsp;
                                                <span className="detail-card-value">{props.defMembership.business?.currency}
                                                      {" "}{props.state.performance.pending?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                          </div>
                                    </div>
                              </div>
                        </div>)}

                        {page === "plan-detail" && (
                              <div className='grid nested-grid main-card'>
                                    <section className='col-6'>
                                          <div className='col-12'>
                                          <div className='flex '>
                                                      {
                                                            planDetState.plan?.status === "ARCHIVED" ?
                                                                  <span className="status-archived" >
                                                                        {planDetState.plan?.status}
                                                                  </span> : <span className="planDetailCard-badge status-active">
                                                                        {planDetState.plan.status}
                                                                  </span>
                                                      }

                                                      <div className='archive-info text-center' >
                                                            {createdDate !== lastModifiedDate && <p>
                                                                  <span className='date-label'>{t("product.updated_on")}{":"}</span> <span className="planModifiedDate">{" "}{lastModifiedDate}</span>
                                                            </p>}
                                                            {createdDate === lastModifiedDate && <p>
                                                                  <span className='date-label'>{t("product.created_on")}{":"}</span> <span className="planModifiedDate">{" "}{createdDate}</span>
                                                            </p>}
                                                      </div>
                                                </div>
                                          </div>
                                          <div className='col-12'>
                                                <p className='planDetailCard-name '>{planDetState.plan.name}</p>
                                                <p className='planDetailCard-dec desc-info '>{planDetState.plan.description}</p>
                                                <p className='planDetailCard-group-name '>{planDetState.plan.group?.name?.toUpperCase()}</p>
                                          </div>
                                    </section>
                                    <section className='col-6'>
                                          <div className='col-12 plan-type'>
                                                <span className="planDetailCard-side-amount">{planDetState.plan.policy === "FLEXIBLE" ? t("products.flexible_amount") : planDetState.plan.policy === "SUBSCRIPTION" ? t("products.subscription-billing") : t("products.fixed-billing")}</span>
                                          </div>
                                          <div className='col-12'>
                                                <div className='grid'>
                                                      <div className='col-6'>
                                                            <div className="planDetailCard-side-info">
                                                                  {t("price")}:
                                                                  <div>
                                                                        {planDetState.plan.cost ? (
                                                                              <p className='ml-1' style={{ color: "#d0021b", fontWeight: "bold" }}>
                                                                                    {`${defMembership.business.currency}${planDetState.plan.cost.toLocaleString("en-US", {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2,
                                                                                    })}`}
                                                                              </p>
                                                                        ) :
                                                                              planDetState.plan.flex ? (<p className='ml-1' style={{ fontSize: "12px", color: "#d0021b" }}>
                                                                                    {t("products.flexible-amount")}
                                                                              </p>)
                                                                                    :
                                                                                    (
                                                                                          <p className='ml-1' style={{ fontSize: "12px", color: "#d0021b" }}>
                                                                                                {t("products.per_sub")}
                                                                                          </p>
                                                                                    )}
                                                                  </div>
                                                            </div>
                                                            <p className="planDetailCard-side-info">
                                                                  {<>{t("billing_period")}:<span>{planDetState.plan.billingPeriod ? t(`product.${planDetState.plan.billingPeriod.toLowerCase()}`) : "--"}</span>
                                                                  </>}
                                                            </p>
                                                            <p className="planDetailCard-side-info">
                                                                  <>{t("grace_period")} : <span>{planDetState.plan.graceDays ? planDetState.plan.graceDays : t("none")}</span></>
                                                                  <span style={{ margin: "0 0 0 2px" }}>{planDetState.plan.graceDays ? t("days") : null} </span>
                                                            </p>
                                                      </div>
                                                      <div className='col-6'>
                                                            {planDetState.plan.setup && <div className="setup_fee_div" style={{ marginTop: "0.5rem" }}>
                                                                  <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                                                                  <span className="setup_fee_value">{defMembership.business.currency}{" "}{planDetState.plan.setup?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </div>}
                                                            <p className="planDetailCard-side-info">
                                                                  {<>{t("product.billing_cycles")}:<span>{planDetState.plan?.deductions ? planDetState.plan.deductions : t("forever")}</span></>}
                                                            </p>
                                                            <p className="planDetailCard-side-info">
                                                                  <>{t("product.reminderDaysLabel")} : <span>{planDetState.plan.reminderDays ? planDetState.plan.reminderDays : t("none")}</span></>
                                                                  <span style={{ margin: "0 0 0 2px" }}>{planDetState.plan.reminderDays ? t("days") : null} </span>
                                                            </p>
                                                      </div>
                                                </div>
                                          </div>
                                    </section>
                              </div>
                        )}

                        {page === "subscription-detail" && (<div className='grid'>
                              <section className='col-6'>
                                    <div className='col-12'>
                                          <div className='flex '>
                                                <span className={`planDetailCard-badge status-${subscribers.status?.toLowerCase()} status-design`}>
                                                      {subscribers.status}
                                                </span>
                                                <div className='archive-info text-center' >
                                                      {subscriptionCreatedOnDate !== subscriptionLastModifiedOnDate && <p>
                                                            <span className='date-label'>{t("product.updated_on")}{":"}</span> <span className="planModifiedDate">{" "}{subscriptionLastModifiedOnDate}</span>
                                                      </p>}
                                                      {subscriptionCreatedOnDate === subscriptionLastModifiedOnDate && <p>
                                                            <span className='date-label'>{t("product.created_on")}{":"}</span> <span className="planModifiedDate">{" "}{subscriptionCreatedOnDate}</span>
                                                      </p>}
                                                </div>
                                          </div>
                                    </div>
                                    <div className='col-12'>
                                          <div className='flex'>
                                                <p className='subscriptionDetailCard-phoneNo '>{subscribers.businessCustomer?.phoneNo}</p>
                                                <p className='subscriptionDetailCard-stroke mr-2 ml-2'>|</p>
                                                <p className='subscriptionDetailCard-name'>{subscribers.businessCustomer?.name}</p>
                                          </div>
                                          <p className='planDetailCard-name '>{subscribers.plan && subscribers.plan.name}</p>
                                          <p className='planDetailCard-dec desc-info '>{subscribers.plan && subscribers.plan.description}</p>
                                          <p className='planDetailCard-group-name '>{subscribers.plan && subscribers.plan.groupName.toUpperCase()}</p>
                                    </div>
                              </section>
                              <section className='col-6'>
                                    <div className='col-12 plan-type'>
                                          <span className="planDetailCard-side-amount">{subscribers.plan?.policy === "FLEXIBLE" ? t("products.flexible_amount") : subscribers.plan?.policy === "SUBSCRIPTION" ? t("products.subscription-billing") : t("products.fixed-billing")}</span>
                                    </div>
                                    <div className='col-12 pb-0'>
                                          <div className="planDetailCard-side-info mt-0">
                                                <>{t("subscriber.subs_id")}: <p className='ml-1' style={{ color: "#9747ff", fontWeight: "bold" }}>
                                                      {subscribers.subscriberReference}</p>
                                                </>
                                          </div>
                                    </div>
                                    <div className='col-12'>
                                          <div className='grid'>
                                                <div className='col-6'>
                                                      <div className="planDetailCard-side-info">
                                                            <>{t("price")}: {subscribers?.plan?.policy === "SUBSCRIPTION" ?
                                                                  <p className='ml-1 flex_label'>
                                                                        {t("subscriber.per_sub")}
                                                                  </p> : subscribers?.plan?.policy === "FLEXIBLE" ? <p className='ml-1 flex_label'>{t("subscriber.flex_true")}</p>
                                                                        : <p className="ml-2" style={{ color: "#d0021b", fontWeight: "bold" }}>
                                                                              {defMembership.business.currency} {subscribers.plan?.cost &&
                                                                                    subscribers.plan?.cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        </p>}
                                                            </>
                                                      </div>
                                                      <p className="planDetailCard-side-info">
                                                            {<>{t("billing_period")}:<span>{subscribers.plan?.billingPeriod ? t(`product.${subscribers.plan?.billingPeriod.toLowerCase()}`) : t("subscriber.none")}</span>
                                                            </>}
                                                      </p>
                                                      <p className="planDetailCard-side-info">
                                                            <>{t("grace_period")} : <span>{subscribers.plan?.graceDays ? subscribers.plan?.graceDays : t("subscriber.none")}</span></>
                                                            <span style={{ margin: "0 0 0 2px" }}>{subscribers.plan?.graceDays ? t("days") : null} </span>
                                                      </p>
                                                </div>
                                                <div className='col-6'>
                                                      {subscribers.plan?.setup && <div className="setup_fee_div" style={{ marginTop: "0.5rem" }}>
                                                            <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                                                            <span className="setup_fee_value">{defMembership.business.currency}{" "}{subscribers.plan?.setup?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                      </div>}
                                                      <p className="planDetailCard-side-info">
                                                            {<>{t("product.billing_cycles")}:<span>{subscribers.plan?.deductions ? subscribers.plan?.deductions : t("forever")}</span></>}
                                                      </p>
                                                      <p className="planDetailCard-side-info">
                                                            <>{t("product.reminderDaysLabel")} : <span>{subscribers.plan?.reminderDays ? subscribers.plan?.reminderDays : t("subscriber.none")}</span></>
                                                            <span style={{ margin: "0 0 0 2px" }}>{subscribers.plan?.reminderDays ? t("days") : null} </span>
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                              </section>
                        </div>)}

                        {page === "withdrawal-list" && (<div className="grid pl-1 pr-1">
                              <div className="col-7">
                                    <div className="flex">
                                          {withdrawalState.planGroup.exclusive ? <p className={`exclusive_tag mr-2`}>{t("products.EXCLUSIVE")}</p> :
                                                <p className={`non-exclusive_tag mr-2`}>{t("products.NON-EXCLUSIVE")}</p>}
                                          <div className="flex">
                                                {(withdrawalState.planGroup.status === "ACTIVE") ? <div className="active_status stat-pill ">{withdrawalState.planGroup.status}</div> : <span className="archive_status stat-pill">{withdrawalState.planGroup.status}</span>}
                                                <div className="ml-1  text-center "> <span className="createdOn_des ml-2">{t("transaction.created_on")}</span>{":"}{" "}<span className="planModifiedDate">{moment(withdrawalState.planGroup?.createdOn).format("MMMM D YYYY")}</span></div>
                                          </div>
                                    </div>
                                    <div className="flex flex-column text-start">
                                          <span className="font-bold  mb-1 plan-name">{withdrawalState.planGroup?.name}</span>
                                          <span className="">{withdrawalState.planGroup?.description}</span>
                                    </div>
                              </div>

                              <div className="col-5">
                                    <div className="mb-3">
                                          <span className="font-bold  bal-size">{t("Balance")}{":"}</span>
                                          <span className="bal-value text-center">{" "}{defMembership?.business?.currency} {Number(productAccountBalances?.balance).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </div>
                                    <div className="mb-1">
                                          <span className="availableTransaction">{t("available")}{":"}</span>
                                          <span className="avail-value">{" "}{defMembership?.business?.currency} {Number(productAccountBalances?.available).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </div>
                                    <div className="">
                                          <span className="pendingTransaction">{t("pending")}{":"}</span>
                                          <span className="avail-value">{" "} {defMembership?.business?.currency} {Number(productAccountBalances?.pendingWthdlAmount).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    </div>
                              </div>
                        </div>)}
                  </Card>
            </div>
      )
}

const ProductCards = ({ style, title, value, subDesc, cardInfo, className }) => {

      const titleValue = (val, title) => {
            if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && val) {
                  return "";
            }
            else if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && !val) {
                  return "";
            }
            else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && val) {
                  return val
            }
            else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && !val) {
                  return 0
            }
      }
      return (
            <div className='col-3'>
                  <div className="product_cards">
                        <div className='grid'>
                              <div className="col-10" style={{ display: "flex", flexDirection: "column" }}>
                                    <span className={`product-card-titles + ${style} ${className}`}>{title}</span>
                                    <span className="product-card-text">{titleValue(value, title)}</span>
                                    <span className="product-summary-card-subDesc">{subDesc}</span>
                              </div>

                              <div className="col-2">
                                    <Tooltip target=".custom-target-icon" />
                                    {cardInfo && <i className='custom-target-icon pi pi-info-circle' id='card-info-btn' data-pr-tooltip={cardInfo}>
                                    </i>}
                              </div>
                        </div>
                  </div>
            </div>
      )
}

const ProductSummaryCards = ({ style, title, cardInfo, type,
      subsNewValue, subtitleNew, subsActiveValue, subtitleActive, subtitleCompleted,
      subsCompletedValue, subsCancelledValue, subtitleCancelled, subsPausedValue, subtitlePaused,
      missedValue, missedSubDesc, revenueValue, revenueSubDesc, collectedValue, collectedSubDesc,
      loading, subsActiveDesc, subsPausedDesc, subsNewDesc, subsCancelledDesc, subsCompletedDesc,
      processedWithdrawalValue, reversedWithdrawalValue, pendingWithdrawalValue, reversedWithdrawalDesc,
      pendingWithdrawalDesc, processedWithdrawalDesc, subCompletedDesc, subActiveDesc, subCancelledDesc,
      subPausedDesc, pendingSevenDayValue, pendingSevenDay, missedSevenDayValue, missedSevenDay,
      revenueSevenDayValue, revenueSevenDay, collectedSevenDayValue, collectedSevenDay, pendingthirtyDayValue,
      pendingthirtyDay, missedthirtyDayValue, missedthirtyDay, revenuethirtyDayValue, revenuethirtyDay,
      collectedthirtyDayValue, collectedthirtyDay, pendingLifetimeValue, pendingLifetime, missedLifetimeValue,
      missedLifetime, revenueLifetimeValue, revenueLifetime, collectedLifetimeValue, collectedLifetime,
      requestedBy, dataFromWithrawalList, dateClassName, recipient, dataFromSubscriberDetail, currency, flexAmount
}) => {
      return (
            <div className="col-4">
                  <div className='products-report-content' >
                        <div className='grid'>
                              <div className='col-10'> <span className={`card-titles + ${style}`}>{title}</span></div>
                              <Tooltip target=".custom-target-icon" />
                              <div className='col-2'>
                                    {cardInfo && <i className='custom-target-icon pi pi-info-circle' id='card-info-btn'
                                          data-pr-tooltip={cardInfo}>
                                    </i>}
                              </div>
                        </div>
                        <div className="summary-records">
                              {type === "subs" && <div>
                                    <div className='grid'>
                                          <div className='col-6'>
                                                <div className="column-view column-view-mb">
                                                      <span className="green-success-txts">{subtitleNew}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsNewValue}</span>&nbsp;
                                                            {subsNewValue > 1 ? <span className="subsTitle">{subsNewDesc}</span> :
                                                                  <span className="subsTitle">{subActiveDesc}</span>}
                                                      </div>
                                                </div>

                                                <div className="column-view column-view-mb">
                                                      <span className="yellow-progress-txts">{subtitlePaused}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsPausedValue}</span>&nbsp;
                                                            {subsPausedValue > 1 ? <span className="subsTitle">{subsPausedDesc}</span> :
                                                                  <span className="subsTitle">{subPausedDesc}</span>}
                                                      </div>
                                                </div>

                                                <div className="column-view">
                                                      <span className="red-cancelled-txts">{subtitleCancelled}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsCancelledValue}</span>&nbsp;
                                                            {subsCancelledValue > 1 ? <span className="subsTitle">{subsCancelledDesc}</span> :
                                                                  <span className="subsTitle">{subCancelledDesc}</span>}
                                                      </div>
                                                </div>
                                          </div>

                                          <div className='col-6'>
                                                <div className="column-view column-view-mb">
                                                      <span className="green-success-txts">{subtitleActive}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsActiveValue}</span>&nbsp;
                                                            {subsActiveValue > 1 ? <span className="subsTitle">{subsActiveDesc}</span> :
                                                                  <span className="subsTitle">{subActiveDesc}</span>}
                                                      </div>
                                                </div>

                                                <div className="column-view">
                                                      <span className="grey-completed-txt">{subtitleCompleted} </span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsCompletedValue}</span>&nbsp;
                                                            {subsCompletedValue > 1 ? <span className="subsTitle">{subsCompletedDesc}</span> :
                                                                  <span className="subsTitle">{subCompletedDesc}</span>}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "payments" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div >
                                                      <span className="green-success-txts">{collectedSubDesc}</span>
                                                      <span>{collectedValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{missedSubDesc}</span>
                                                      <span>{missedValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <span className="blue-revenue-txt">{revenueSubDesc}</span>
                                                <span>{revenueValue}</span>
                                          </div>
                                    </div>
                              </div>}

                              {type === "withdrawals" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{processedWithdrawalDesc}</span>
                                                      <span>{processedWithdrawalValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{reversedWithdrawalDesc}</span>
                                                      <span>{reversedWithdrawalValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="yellow-progress-txts">{pendingWithdrawalDesc}</span>
                                                      <span>{pendingWithdrawalValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "payments-7" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{collectedSevenDay}</span>
                                                      <span>{collectedSevenDayValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="yellow-progress-txts">{pendingSevenDay}</span>
                                                      <span>{pendingSevenDayValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="blue-revenue-txt">{revenueSevenDay}</span>
                                                      <span>{revenueSevenDayValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{missedSevenDay}</span>
                                                      <span>{missedSevenDayValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "payments-30" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{collectedthirtyDay}</span>
                                                      <span>{collectedthirtyDayValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="yellow-progress-txts">{pendingthirtyDay}</span>
                                                      <span>{pendingthirtyDayValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="blue-revenue-txt">{revenuethirtyDay}</span>
                                                      <span>{revenuethirtyDayValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{missedthirtyDay}</span>
                                                      <span>{missedthirtyDayValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "lifetime" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{collectedLifetime}</span>
                                                      <span>{collectedLifetimeValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="yellow-progress-txts">{pendingLifetime}</span>
                                                      <span>{pendingLifetimeValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="blue-revenue-txt">{revenueLifetime}</span>
                                                      <span>{revenueLifetimeValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{missedLifetime}</span>
                                                      <span>{missedLifetimeValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {`${type}` && <div>
                                    {dataFromSubscriberDetail?.map(({ receivedAt, amount, cost, plan, subscription, latestDlr, scheduledFor }, i) => (
                                          <div key={i} className="grid space-bottom">
                                                <div className="col-6 pb-0">
                                                      <p className={`pb-0 mb-0 ${dateClassName}`}>
                                                            {moment(type === "subscriber-details-1" ? scheduledFor
                                                                  : type === "subscriber-details-2" ? receivedAt
                                                                        : type === "subscriber-details-3" ? latestDlr?.dispatchedOn
                                                                              : null).format("ll")}
                                                      </p>
                                                </div>
                                                <div className='col-6 pb-0'>
                                                      {subscription?.plan?.flex ? (
                                                            <p className="pb-0 mb-0 account_name">{flexAmount}</p>
                                                      ) : (
                                                            <p className="pb-0 mb-0 account_name">
                                                                  {currency}{" "}
                                                                  {(cost || amount)?.toLocaleString("en-US", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                  })}
                                                            </p>
                                                      )}
                                                </div>

                                                <div className="col-12 pt-0">
                                                      <p className="pending-value pb-0">{subscription?.plan?.name || plan?.name}</p>
                                                </div>
                                          </div>
                                    ))}
                              </div>
                              }

                              {`${type}` && <div>
                                    <div className="grid">
                                          <div className="col-12">
                                                <div className="grid">
                                                      {dataFromWithrawalList?.map(({ requestedOn, amount, channel, requestedByName }, i) => (
                                                            <div key={i} className="grid nested-grid space-bottom">
                                                                  <div className="grid col-12 pb-0">
                                                                        <p className={`col-6 pb-0 mb-0 ${dateClassName}`}>
                                                                              {moment(requestedOn).format("ddd, MMM D, YYYY")}
                                                                        </p>
                                                                        <p className="col-6 pb-0 mb-0 account_name">
                                                                              {channel?.business.currency}{" "}
                                                                              {amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        </p>
                                                                  </div>
                                                                  <div className="col-12 flex pt-0 pb-0">
                                                                        <p className="requested-info mb-0 mt-0 mr-1">{requestedBy}:</p>
                                                                        <p className="pending-value mb-0">{requestedByName}</p>
                                                                  </div>
                                                                  <div className="col-12 flex pt-0 mb-1">
                                                                        <p className="requested-info mb-0 mt-0 mr-1">{recipient}:</p>
                                                                        <p className="pending-value mb-0">{channel?.name}</p>
                                                                  </div>
                                                            </div>
                                                      ))}
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {loading ? <div className="card flex justify-content-center">
                                    <ProgressSpinner id="progress-spinner" />
                              </div> : <></>}
                        </div>
                  </div>
            </div>
      )
}

export const FullWidthCardTemplate = ({ summary, loading }) => {
      return (
            <>
                  {summary.map((summaryContent, i) => (
                        <LargeCard {...summaryContent} loading={loading} key={i} />
                  ))}

            </>
      )
}

export const SmallCardTemplate = ({ summary, loading, }) => {
      return (
            <>
                  {summary.map((summaryContent, index) => (
                        <ProductCards{...summaryContent} loading={loading} key={index} />
                  ))}
            </>
      )
}

export const MediumCardTemplate = ({ summary, header = "", loading, }) => {
      return (
            <>
                  <span className="wallet-titles summary-title">{header}</span>
                  {
                        summary.map((productsSummaryContent, i) => (
                              <ProductSummaryCards{...productsSummaryContent} loading={loading} key={i} />
                        ))
                  }
            </>
      )
}