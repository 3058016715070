import React, { useEffect } from "react";
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import App from "../Layout/App";
import Login from "../Pages/Login";
import Error from "../Pages/Error";
import NotFound from "../Pages/NotFound";
import NoAccess from "../Pages/NoAccess";
import ImplicitCallback from "../Pages/Authentication";
import ConfigureBusiness from "../Pages/Business/Configure";
import { componentRoutes, permittedRoleList, lockedRoutes, notAdminEntLockedRoutes } from "./ComponentRoutes";
import Authenticate from "../Pages/Authentication/Authenticate";
import EmailVerification from "../Pages/Authentication/EmailVerification";
import { ProtectedRoute } from "../Pages/Authentication/keycloakProvider";


function AppWrapper() {
  const user = useSelector((state) => state.defMembership.defMembership);
  const location = useLocation();
  

  useEffect(() => {
    window.scrollTo(0, 0);
    if(location.pathname !== "/"){
    }
  }, [location]);

  const validPath = (routeArray) => {
    
    return routeArray.some((route) => matchPath(route.path, location.pathname));
  };

  const findRoute = (path) => {
    return lockedRoutes.find((element) => element.path === path);
  };

  const findRole = permittedRoleList.includes(user?.role);
  const isBusinessEnterprise = user?.business?.enterprise;

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/configure" element={<ConfigureBusiness />} />
      <Route path="/verification" element={<EmailVerification />} />
      <Route path="/login" element={<Authenticate />} />
      <Route path="/error" element={<Error />} />
      <Route path="/implicit/callback" element={<ImplicitCallback />} />
      {!validPath(componentRoutes) ? (
        <Route path="*" element={<NotFound />} />
      ) :user && !findRole && findRoute(location.pathname) ? (
        <Route path="*" element={<NoAccess />} />
      ) 
      //This is commented out for now but might be needed in the future
      // :user && isBusinessEnterprise && enterpriseLockedRoutes.some(route => route.path === location.pathname) ? (
      //   <Route path="*" element={<NoAccess />} />
      // )
       :user && isBusinessEnterprise && !findRole && notAdminEntLockedRoutes.some(route => route.path === location.pathname) ? (
        <Route path="*" element={<NoAccess />} />
      ) :user && !isBusinessEnterprise && (location.pathname === '/subscriptions/activity' || location.pathname === '/subscriptions/invitations') ? (
        <Route path="*" element={<NoAccess />} />
      ) : user && isBusinessEnterprise && findRole && (location.pathname === '/invitations') ? (
        <Route path="*" element={<NotFound />} />
      ) : (
        <Route path="*" element={
          <ProtectedRoute>
        <App />
        </ProtectedRoute>
      } />
      )}
    </Routes>
  );
}

export default AppWrapper;