import { combineReducers } from "redux";

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
	account: require('./account-reducer').reducer,
	defMembership: require('./defMembership-reducer').reducer,
	countries: require('./country-reducer').reducer,
	businesses: require('./business-reducer').reducer,
	signup: require('./signup-reducer').reducer,
	plans: require('../transition/plan/plan.reducer').reducer,
	// customers: require('../transition/customer/customer.reducer').reducer,
	subscribers: require('../transition/subscriber/subscriber.reducer').reducer,
	memberships: require('../transition/membership/membership.reducer').reducer,
	phoneVerifications: require('../transition/phone-verification/phone-verification.reducer').reducer,
	membershipInvites: require('../transition/membership-invite/membership-invite.reducer').reducer,
	selfSignups: require('../transition/self-signup/self-signup.reducer').reducer,
	profile: require('./profile.reducer').reducer,
	paymentSchedules: require('../transition/payment-schedule/payment-schedule.reducer').reducer,
	paymentTracks: require('../transition/payment-track/payment-track.reducer').reducer,
	wallet: require('../transition/wallet/wallet.reducer').reducer,
	settlements: require('../transition/settlement/settlement.reducer').reducer,
	planGroups: require('../transition/plan-group/plan-group.reducer').reducer,
	payoutChannels: require('./payoutchannel-reducer').reducer,
	banks: require('./bank-reducer').reducer,
	bankBranches: require('./bankBranches-reducer').reducer,
	enterprise: require('../transition/enterprise/enterprise.reducer').reducer,
	documents: require("../transition/document-upload/document-upload.reducer").reducer,
	businessCustomers: require("../transition/customers/business-customer.reducer").reducer,
	planReport: require('../transition/plan-report/plan-report.reducer').reducer,
	report: require("../transition/report/report.reducer").reducer,
	payswitch: require("../transition/payswitch/payswitch.reducer").reducer,
	beginOnboarding: require("../transition/beginOnboarding/beginOnboarding.reducer").reducer
})