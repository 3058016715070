import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import PaymentKeysSettings from './PaymentKeysSettings';
import { Button } from 'primereact/button';
import { useTranslation } from "react-i18next";
import BeginOnboardingRequestActions from "../../Redux/transition/beginOnboarding/beginOnboarding.reducer";
import BusinessActions from "../../Redux/actions/business-actions";
import TelecelCashLogo from "../../assets/images/Telecel-Cash-Logo.jpg";
import ATMoneyLogo from "../../assets/images/airteltigo-money-logo.png";
import MTN_MomoLogo from "../../assets/images/momo.jpg";
import Mastercard from "../../assets/images/mastercard.png";
import Visacard from "../../assets/images/visacard.png";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

function ProviderDetailPaySwitch() {

    // eslint-disable-next-line no-unused-vars
    const [state, setState] = useState({
        loading: true,
        // isOnboardingInProgress: false,
        paymentCredentials: null,
        businessObjects: {},
    })

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const onboardingRequestInProgress = useSelector((state) => state.beginOnboarding.onboardingRequestInProgress);
    const onboardingRequestStarted = useSelector((state) => state.beginOnboarding.onboardingRequestStarted);
    const failureOnboarding = useSelector((state) => state.beginOnboarding.errorOnboardingRequest);
    const verifStatus = useSelector((state) => state.documents.verifStatus);
    const paymentCredentials = useSelector((state) => state.payswitch.credentials);
    const business = useSelector((state) => state.businesses.business);

    const dispatch = useDispatch();
    const getBusiness = useCallback((businessId) => { dispatch(BusinessActions.businessRequest(businessId)) }, [dispatch]);
    const onboardingRequest = useCallback((businessId, paymentProvider) => { dispatch(BeginOnboardingRequestActions.beginOnboardingRequest(businessId, paymentProvider)) }, [dispatch]);

    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const id = business?.id
    const activateProviderToast = useRef(null);
    // eslint-disable-next-line no-unused-vars
    const [showPaymentProvider, setShowPaymentProvider] = useState("PAYSWITCH");
    const [isOnboardingInProgress, setIsOnboardingInProgress] = useState(false);

    const activateProviderErrorMessage = () => {
        activateProviderToast.current.show({ severity: 'error', summary: t("api_settings.error"), detail: t("api_settings.verification_started_error_info"), life: 5000 });
    }

    const activateProviderSuccessMessage = () => {
        activateProviderToast.current.show({ severity: 'success', summary: t("api_settings.success"), detail: t("api_settings.verification_started_success_info"), life: 3000 });
    }

    useEffect(() => {
        if (isOnboardingInProgress && !onboardingRequestInProgress && !onboardingRequestStarted) {
            activateProviderSuccessMessage();
        }
        if (isOnboardingInProgress && !onboardingRequestInProgress && failureOnboarding) {
            activateProviderErrorMessage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [failureOnboarding, onboardingRequestInProgress])

    const onboardingRequestFn = () => {
        onboardingRequest(id, showPaymentProvider);
    }

    return (
        <div>
            <Toast ref={activateProviderToast}
                onRemove={(message) => {
                    if (message.severity === "success")
                        getBusiness(id);
                    setIsOnboardingInProgress(false);
                }} />

            <Card className="payment_settings_main_card">
                <div className="settings_card_div">

                    <div className="grid" id="main_top_tags">

                        <div className="col-6 left_tags_texts">

                            <Button onClick={() => navigate(-1)} id="back_btn">
                                <i className="pi pi-arrow-left backBtn-icon"></i>
                            </Button>

                            <div className="left_texts">
                                <p className="paySwitch_txt">{t("login.payswitch_txt")}</p>
                            </div>

                            {defMembership.business.pmtProvider !== "PAYSWITCH" && <span id="not_activated_label">{t("api_settings.not_activated_label")}</span>}
                            {(defMembership.business.pmtProvider === "PAYSWITCH") && defMembership.business.verified === true &&
                                <span className="active_label">{t("api_settings.active_label")}</span>}
                            {(defMembership.business.pmtProvider === "PAYSWITCH" && verifStatus > 0) && defMembership.business.verified === false && <span className="in_progress_label">{t("api_settings.in_progress_label")} </span>}
                        </div>

                        <div className="col-6 right-buttons">
                            {
                                defMembership.business.pmtProvider !== "PAYSWITCH" &&
                                <Button
                                    id="activate_provider"
                                    onClick={() => {
                                        onboardingRequestFn()
                                        setIsOnboardingInProgress(true)
                                    }}>{onboardingRequestInProgress ? t("api_settings.activating") : t("api_settings.activate_provider")}
                                </Button>}

                            {/* A story wil be created to handle the function of these buttons */}
                            {defMembership.business.pmtProvider === "PAYSWITCH" && < Button
                                id="switch_provider"
                                label={t("api_settings.switch_provider")}
                                onClick={() => {
                                }} />}
                            {defMembership.business.pmtProvider === "PAYSWITCH" && < Button
                                id="deactivate_label"
                                label={t("api_settings.deactivate_label")}
                                onClick={() => {
                                }} />}
                        </div>
                    </div>

                    <div className="border_about">
                        <div className="inner_div_about">
                            <div className="about_paySwitch_div">
                                <p className="about_paySwitch_p"> {t("login.payswitch_txt")}</p>
                            </div>
                            <p className="about_paySwitch_text1"> {t("api_settings.about_paySwitch_txt1")}</p>
                            <p className="about_paySwitch_text2"> {t("api_settings.about_paySwitch_txt2")}</p>
                        </div>
                    </div>

                    <div className="div_payment_methods">
                        <p className="payment_settings_methods">{t("api_settings.payment_method_text")}</p>
                        <div className="payment_methods_logos">
                            <img src={Mastercard} alt="MasterCard" />
                            <img src={Visacard} alt="VisaCard" />
                            <img src={TelecelCashLogo} alt="TCashLogo" />
                            <img src={MTN_MomoLogo} alt="MTNMomo" />
                            <img src={ATMoneyLogo} alt="ATMoney" />
                        </div>
                    </div>

                    <p className="requirement_txt">{t("api_settings.requirement_txt")}</p>

                    <div className="grid">
                        <div className="col-4">
                            <p className="general_doc_header">{t("api_settings.general_doc_header")}</p>
                        </div>

                        <div className="col-3">
                            <p className="unregistered_header">{t("api_settings.unregistered_header")}</p>
                        </div>

                        <div className="col-4">
                            <p className="special_notes_header">{t("api_settings.special_notes_header")}</p>
                        </div>
                    </div>

                    <hr className="paymentsSettings_hr" />

                    <div className="grid requirement_div">

                        <div className="col-4">
                            <p className="requirement_docs_color">{t("api_settings.general_doc_txt1")}</p>
                            <p className="requirement_docs_color">{t("api_settings.general_doc_txt2")}</p>
                            <p className="requirement_docs_color">{t("api_settings.general_doc_txt3")}</p>
                            <p className="requirement_docs_color">{t("api_settings.general_doc_txt4")}</p>
                        </div>

                        <div className="col-3">
                            <p className="unregistered_docs_color">{t("api_settings.unregistered_txt1")}</p>
                            <p className="unregistered_docs_color">{t("api_settings.unregistered_txt2")}</p>
                        </div>

                        <div className="col-4 special_notes_div">
                            <p className="special_notes_color">{t("api_settings.special_notes_info")}</p>
                        </div>
                    </div>

                    <hr className="paymentsSettings_hr" />

                    <div className="payment-main">
                        {defMembership.business.verified === false ?
                            <div className="no_credentials_heading">
                                <p className='payment_heading1'>{t("business.payment_heading1")}</p>
                                <div className="payment_provider_heading">
                                    <p className="payswitch_no_paymentcredentials_heading"> {t("business.payswitch_no_paymentcredentials_heading")}</p>
                                </div>
                            </div> :
                            <div>
                                <div className='payment_heading1'>{t("business.payment_heading1")}</div>

                                <div className="heading_main_div_payments">
                                    <p className="payment_heading">{t("business.payment_heading")}</p>
                                    <p className="payment_purple_txt">{t("business.payment_middle_heading")}</p>
                                    <p className="payment_heading">{t("business.below_txt")}</p>
                                </div>
                            </div>
                        }
                        {paymentCredentials && <div className='payment-added-alert col-12'><span className='payment-added-alert1'>
                            {t("business.existing_payment1")}</span> <br />
                            <span className='payment-added-alert2'>{t("business.existing_payment2")}</span>
                        </div>}
                        <PaymentKeysSettings />
                    </div>

                </div>
            </Card >
        </div >
    )
}

export default ProviderDetailPaySwitch;