/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BusinessActions from '../../Redux/actions/business-actions';
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import PaySwitchActions from "../../Redux/transition/payswitch/payswitch.reducer";
import BusinessPage from './BusinessPage';
import Api from './Api';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Components/Loading";
import { TabMenu } from "primereact/tabmenu";
import "../../Styles/pages/settings.scss";
import OnboardingNotification from "../../Components/OnboardingNotification";

function Settings() {
  let location = useLocation();
  let navigate = useNavigate();
  const { t } = useTranslation("common");
  const [activeIndex, setActiveIndex] = useState(0);
  const [openNotification, setOpenNotification] = useState(false);

  const defMembership = useSelector((state => state.defMembership.defMembership))
  const verificationStatus = useSelector((state) => state.documents.verifStatus);
  const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
  const business = useSelector((state) => state.businesses.business);
  const fetchingPaymentCredentials = useSelector((state) => state.payswitch.fetchingCredentials);

  //Redux Dispatch Actions
  const dispatch = useDispatch();
  const getBusiness = useCallback((id) => dispatch(BusinessActions.businessRequest(id)), [dispatch]);
  const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])
  const getCredentials = useCallback((businessId) => { dispatch(PaySwitchActions.paySwitchCredentialRequest(businessId)) }, [dispatch]);

  // eslint-disable-next-line no-unused-vars
  const [added, setAdded] = useState(false);
  
  const [state, setState] = useState({
    entityId: null,
    business: {},
    verificationStatus: null,
    notOwnerVisible: false,
    verifPendingVisible: false,
    fetchingVerifRecord: true,
  })

  const items = [
    { label: t("business.business_profile"), className: "tab  tab1" },
    { label: t("business.api"), className: "tab  edge-tab-end", disabled: "true" }
  ];

  // const handleChange = (newValue) => {
  //   // Update parent state based on the child state
  //   setAdded(newValue);
  // };


  useEffect(() => {
    let loading = fetchingVerifStatus;
    setState((state) => { return { ...state, fetchingVerifRecord: loading }; });
    if (business) { setState((state) => ({ ...state, business: business })); }
    if (verificationStatus) { setState((state) => { return { ...state, verifStatus: verificationStatus }; }) }
  }, [fetchingVerifStatus, business, verificationStatus]);

  useEffect(() => {
    if (added) {
      setActiveIndex(0)
    }
  }, [added])

  useEffect(() => {
    if (!defMembership) {
      getMembership();
    }
    if (defMembership) {
      getBusiness(defMembership.business.id)
      getCredentials(defMembership.business.id)
    }
    if (defMembership?.role !== 'OWNER') {
      navigate("/denied")
    }
    if (location.state?.index === 1) {
      setActiveIndex(1)
    }
  }, [])

  useEffect(() => {
    if (defMembership) {
      setState((prev) => {
        return { ...prev, business: defMembership.business, }
      })
    }
  }, [defMembership])

  if (!defMembership) {
    return <Loading />;
  }
  const renderVerifFooter = (component) => {
    return (
      <div>
        <Button
          label={t("common.ok")}
          icon="pi pi-check"
          style={{ backgroundColor: "#d0021b" }}
          autoFocus
          onClick={
            component === "not_owner"
              ? toggleNotOwnerDialogue
              : toggleVerifPendingDialogue
          }
        />
      </div>
    );
  };
  const toggleSetOpenDialog = () => {
    setOpenNotification(!openNotification);
  };
  const toggleVerifPendingDialogue = () => { setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible }; }); };
  const toggleNotOwnerDialogue = () => { setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible }; }); };

  const tooglePayment = () => {
    if (fetchingVerifStatus || fetchingPaymentCredentials) {
      return <Loading />;
    } else {
      if (!business.verified) {
        if (state.verifStatus && !state.fetchingVerifRecord) { return toggleVerifPendingDialogue(); } if (!state.verifStatus && !state.fetchingVerifRecord) {
          switch (defMembership.role) {
            case "OWNER":
              return <OnboardingNotification visible={true} onHide={toggleSetOpenDialog} />
            default:
              toggleNotOwnerDialogue();
          }
        }
      }
    }
  };

  return (
    <div>
      <Dialog
        header={t("idoc.id_verif_pending_header")}
        visible={state.verifPendingVisible}
        onHide={toggleVerifPendingDialogue}
        style={{ width: "360px" }}
        footer={renderVerifFooter("verif_pending")}
      >
        <span>{t("idoc.id_verif_pending_text1")}</span> <span style={{ color: "#d5a326" }}>{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
      </Dialog>
      <Dialog
        header={t("idoc.not_owner_header")}
        visible={state.notOwnerVisible}
        style={{ width: "360px" }}
        onHide={toggleNotOwnerDialogue}
        footer={renderVerifFooter("not_owner")}
      >
        <p>{t("idoc.not_owner_text")}</p>
      </Dialog>
      <div className='settings-tabs'>
        <TabMenu
          model={items}
          activeIndex={activeIndex}
          onTabChange={(e) => {
            if (!business?.verified && e.index === 1) {
              tooglePayment()
              setActiveIndex(0);
            } else {
              setActiveIndex(e.index);
            }
          }}
          style={{ marginTop: "1rem" }}
        />
      </div>
      <div className='settings-card'>
        {state.business &&

          <div>
            {activeIndex === 0 && <BusinessPage />}
            {activeIndex === 1 && <Api />
            }
          </div>
        }
      </div>
    </div>
  )
}
export default Settings