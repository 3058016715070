import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/TRANSPARENT LOGO.svg";
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import { useTranslation } from "react-i18next";
import BusinessActions from "../../Redux/actions/business-actions";
import "../../Styles/pages/pdfv1.scss";

function PosterV1() {

      const { t } = useTranslation("common");
      const componentRef = useRef(null);
      const [qrCodeUrl, setQrCodeUrl] = useState(" ");
      const [businessAccount, setBusinessAccount] = useState({})

      // Redux dispatch actions
      const dispatch = useDispatch()
      const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
      const getBusinessQr = useCallback((businessId) => { dispatch(BusinessActions.businessQrCodeRequest(businessId)) },
            [dispatch]);

      //redux state and dispatch to props
      const defMembership = useSelector((state) => state.defMembership.defMembership);
      const qrCodeSuccess = useSelector((state) => state.businesses.qrCode);

      const reactToPrintContent = React.useCallback(() => {
            return componentRef.current;
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [componentRef.current]);
      const handlePrint = useReactToPrint({
            content: reactToPrintContent
      });

      const businessId = defMembership.business.id;

      useEffect(() => {
            if (!defMembership) {
                  getMembership();
            }
            if (defMembership) {
                  getBusinessQr(businessId)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defMembership, getMembership, businessId]);

      useEffect(() => {

            const arrayBufferToBase64 = (buffer) => {
                  let binary = '';
                  const bytes = new Uint8Array(buffer);
                  bytes.forEach(byte => binary += String.fromCharCode(byte));
                  return btoa(binary);
            };

            if (qrCodeSuccess instanceof ArrayBuffer) {
                  const base64String = arrayBufferToBase64(qrCodeSuccess);
                  setQrCodeUrl(base64String);
            }

            if (defMembership) {
                  setBusinessAccount(defMembership.business)
            }
      }, [qrCodeSuccess, defMembership])

      return (
            <div>
                  <Button label="Print" icon="pi pi-print"
                        onClick={handlePrint}
                        id="print_btn" />
                  <div className={"section-to-print print_section pdf_print_section"} ref={componentRef}>
                        <div style={{ textAlign: "center", marginTop: "2rem" }}>
                              <div className="div_img_heading">
                                    <div className="business_logo">
                                          <img className="image_style" src={businessAccount.logoUrl} alt='business-logo' />
                                    </div>
                                    <div className="main_div_heading">
                                          <span className="bold_heading">{t("business.bold_heading")}</span>
                                          <span className="bold_heading second_bold_heading">{t("business.second_bold_heading")}</span>
                                    </div>
                              </div>

                              <span className="scan_text">{t("business.scan_text")}</span>

                              <div style={{ textAlign: "center" }}>
                                    <img src={`data:image/png;base64,${qrCodeUrl}`}
                                          alt="png" className="pdf_qrCode" />
                              </div>
                              <div>
                                    <div className="process_tag_div">
                                          <span className="process_tags">
                                                1
                                          </span>
                                          <p className="process_tag_hr"></p>

                                          <div className="process_tag_2">
                                                <span className="process_tags">
                                                      2
                                                </span>
                                          </div>
                                          <p className="process_tag_hr"></p>

                                          <div className="process_tag_3">
                                                <span className="process_tags">
                                                      3
                                                </span>
                                          </div>
                                          <p className="process_tag_hr"></p>

                                          <div className="process_tag_4">
                                                <span className="process_tags">
                                                      4
                                                </span>
                                          </div>
                                    </div>
                                    <div className="main_div_process_texts">
                                          <div className="process_texts first_process_texts">
                                                <span className="bold_process_texts">{t("business.open_qr")} </span>
                                                <span className="bold_process_texts">{t("business.app_camera")}</span>
                                          </div>
                                          <div className="process_texts process_texts_second">
                                                <span className="bold_process_texts">{t("business.scan_this")} </span>
                                                <span className="bold_process_texts">{t("business.qr_code")}</span>
                                          </div>
                                          <div className="process_texts process_texts_last_two">
                                                <span className="bold_process_texts">{t("business.choose_plan")} </span>
                                                <span className="bold_process_texts">{t("business.like_text")}</span>
                                          </div>
                                          <div className="process_texts process_texts_last_two">
                                                <span className="bold_process_texts">{t("business.follow_prompts")} </span>
                                                <span className="bold_process_texts">{t("business.sign_up_text")}</span>
                                          </div>
                                    </div>
                              </div>
                              <div className="pdf_footer">
                                    <span className="business_phone">{defMembership.business.phoneNo}</span>
                                    <div>
                                          <span className="powered_text">{t("business.powered_text")}{":"}</span>
                                          <img src={logo} alt="reveknew-logo" id='transparent_logo' />
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>)

}
export default PosterV1;