import { call, put } from "redux-saga/effects";
import BeginOnboardingRequestActions from "./beginOnboarding.reducer";
import * as api from "../../../Service/api";

export function* beginOnboardingRequest(action) {
    const { businessId, paymentProvider } = action;
    // make the call to the api
    const response = yield call(api.beginOnboardingRequest, businessId, paymentProvider);
    // success?
    if (response.ok) {
        yield put(BeginOnboardingRequestActions.beginOnboardingRequestSuccess(response.data));
    } else {
        yield put(BeginOnboardingRequestActions.beginOnboardingRequestFailure(response.data));
    }
}