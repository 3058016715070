import React, { useEffect, useState } from "react";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { Navigate, useLocation } from "react-router-dom";
import keycloak from "./keycloak";

const ProtectedRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (initialized) {
      const timer = setTimeout(() => {
        setIsInitialLoad(false);
      }, 100); 
      return () => clearTimeout(timer);
    }
  }, [initialized]);

  if (!initialized || (isInitialLoad && keycloak.token)) {
    return children;
  }

  if (!keycloak.authenticated && !isInitialLoad) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

const TokenMonitor = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) return;

    const checkTokenValidity = async () => {
      try {
        const timeToExpire = keycloak.tokenParsed.exp - (Date.now() / 1000);
        
        if (timeToExpire < 30) {
          const updated = await keycloak.updateToken(30);
          if (!updated) {
            await keycloak.logout();
          }
        }
      } catch (error) {
        await keycloak.logout();
      }
    };

    checkTokenValidity();
    const checkInterval = setInterval(checkTokenValidity, 60000);
    return () => clearInterval(checkInterval);
  }, [keycloak]);

  return null;
};

const KeycloakProviderWrapper = ({ children }) => (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: "check-sso",
      checkLoginIframe: false,
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      pkceMethod: "S256",
      promiseType: "native",
      enableLogging: true
    }}
  >
    <TokenMonitor />
    {children}
  </ReactKeycloakProvider>
);

export default KeycloakProviderWrapper;
export { ProtectedRoute };