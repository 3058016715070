import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubscriberActions from "../../../../Redux/transition/subscriber/subscriber.reducer";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from "react-i18next";
import DefMembershipActions from "../../../../Redux/actions/defMembership-actions";
import PaymentTrackActions from "../../../../Redux/transition/payment-track/payment-track.reducer";
import PaymentScheduleActions from "../../../../Redux/transition/payment-schedule/payment-schedule.reducer";
import moment from "moment";
import { useParams } from "react-router-dom";
import Loading from "../../../../Components/Loading"
import "../../../../Styles/pages/subscriptions.scss"
import "../../../../Styles/pages/product.scss"
import DetailCard from "./DetailCard";
import TransactionsTabs from './TransactionsTabs'

// NB: anything marking or mark is related to marking a payment as externally received 
// i.e. recording a payment received through any payment method other than Reveknew's known payment channel as received
const Subscribers = (props) => {

	const { t } = useTranslation("common");
	const cancelToast = useRef(null)
	const pauseToast = useRef(null)
	const resumeToast = useRef(null)
	const rescheduleToast = useRef(null)
	const [confirmPause, setConfirmPause] = useState(false)
	const [confirmResume, setConfirmResume] = useState(false)
	const [confirmCancel, setConfirmCancel] = useState(false)
	const [confirmReschedule, setConfirmReschedule] = useState(false)
	const [date, setDate] = useState(null);
	const [state, setState] = useState({
		subscriberObjects: {},
		paymentTracks: [],
		missedSchedules: [],
		scheduleId: null,
		date: null,
		paymentData: [],
		marking: true,
		cancelled: false,
		response: null,
		paused: false,
		missedPaymentCount: null,
		resumed: false,
		rescheduled: false,
		paymentSchedulesObjects: null,
		key: false,
		page: 0,
		size: 15,
		sort: 'receivedAt,desc',
		sortOrder: 'id,desc',
		showToast: false,
		subsExternalPayments: [],
		missedSchedulesCount: null,
		count: null,
		revenueObject: {},
		subscriptionSummary: {}
	});

	let today = new Date();

	let { id } = useParams();

	const defMembership = useSelector((state) => state.defMembership.defMembership)
	const paymentSchedules = useSelector((state) => state.paymentSchedules.paymentSchedules)
	const paymentSchedulesMissed = useSelector((state) => state.paymentSchedules.paymentSchedulesMissed)
	const fetchingSchedule = useSelector((state) => state.paymentSchedules.fetchingSchedule)
	const paymentTracks = useSelector((state) => state.paymentTracks.paymentTracks)
	const fetchingTracks = useSelector((state) => state.paymentTracks.fetchingTracks)
	const subscriber = useSelector((state) => state.subscribers.subscriber)
	const fetchingSubscriber = useSelector((state) => state.subscribers.fetchingOne)
	const missedPaymentCount = useSelector((state) => state.paymentSchedules.missedPaymentSchedulesCount)
	const cancelledSubscription = useSelector((state) => state.subscribers.cancelledSubscription)
	const cancellingSubscription = useSelector((state) => state.subscribers.cancellingSubscription)
	const pausingSubscription = useSelector((state) => state.subscribers.pausingSubscription)
	const pausedSubscription = useSelector((state) => state.subscribers.pausedSubscription)
	const resumingSubscription = useSelector((state) => state.subscribers.resumingSubscription)
	const resumedSubscription = useSelector((state) => state.subscribers.resumedSubscription)
	const rescheduledSubscription = useSelector((state) => state.subscribers.rescheduledSubscription)
	const reschedulingSubscription = useSelector((state) => state.subscribers.reschedulingSubscription)
	const subsPayments = useSelector((state) => state.paymentTracks.subsPayments)
	const paymentTracksCount = useSelector((state) => state.paymentTracks.paymentTracksCount)
	const missedPaymentSchedulesCount = useSelector((state) => state.paymentSchedules.missedPaymentSchedulesCount)
	const subscriberRevenue = useSelector((state) => state.subscribers.subscriberRevenue)
	const subscriptionSummary = useSelector((state) => state.subscribers.subscriptionSummary)

	const dispatch = useDispatch()
	const getSubscriber = useCallback((subscriberId) => { dispatch(SubscriberActions.subscriberRequest(subscriberId)) }, [dispatch])
	const getMembership = useCallback((subscriberId) => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])
	// const getMissedPaymentSchedulesCount = useCallback((subscriberId, options) => { dispatch(PaymentScheduleActions.missedPaymentSchedulesCountRequest(subscriberId, options)) }, [dispatch])
	const getAllPaymentSchedules = useCallback((subscriberId, options) => { dispatch(PaymentScheduleActions.paymentScheduleAllRequest(subscriberId, options)) }, [dispatch])
	const getAllPaymentTracks = useCallback((subscriberId, options) => { dispatch(PaymentTrackActions.paymentTrackAllRequest(subscriberId, options)) }, [dispatch])
	const getAllPaymentTracksCount = useCallback((subscriberId, options) => { dispatch(PaymentTrackActions.paymentTracksCountRequest(subscriberId, options)) }, [dispatch])
	const cancelSubscriptions = useCallback((subscriptionId, membershipId) => { dispatch(SubscriberActions.cancelSubscriptionRequest(subscriptionId, membershipId)) }, [dispatch])
	const pauseSubscriptions = useCallback((subscriptionId, membershipId) => { dispatch(SubscriberActions.pauseSubscriptionRequest(subscriptionId, membershipId)) }, [dispatch])
	const resumeSubscriptions = useCallback((id, startFrom, membershipId, action) => { dispatch(SubscriberActions.resumeSubscriptionRequest(id, startFrom, membershipId, action)) }, [dispatch])
	const rescheduleSubscriptions = useCallback((subscriberId, startFrom, membershipId) => { dispatch(SubscriberActions.rescheduleSubscriptionRequest(subscriberId, startFrom, membershipId)) }, [dispatch])
	const getMissedPaymentSchedulesCount = useCallback((subscriberId, options) => { dispatch(PaymentScheduleActions.missedPaymentSchedulesCountRequest(subscriberId, options)) }, [dispatch])
	const getTotalSubscriberRevenue = useCallback((subscriberId) => { dispatch(SubscriberActions.subscriberRevenueRequest(subscriberId)) }, [dispatch])
	const getSubscriptionSummary = useCallback((subscriptionId) => { dispatch(SubscriberActions.subscriptionSummaryRequest(subscriptionId)) }, [dispatch])

	//Handle side effects that run once (aka: componentDidMount)
	useEffect(() => {
		getSubscriber(id)
		getAllPaymentTracks(id, { page: state.page, sort: state.sort, size: state.size });
		getAllPaymentTracksCount(id);
		getMissedPaymentSchedulesCount(id, { page: state.page, sort: state.sortOrder, size: state.size });
		// getMissedPaymentSchedulesCount(id, { page: state.page, sort: state.sortOrder, size: state.size })
		getTotalSubscriberRevenue(id)
		getSubscriptionSummary(id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	//Handle side effects (aka: componentDidUpdate)
	useEffect(() => {

		if (!defMembership) {
			getMembership();
		}
		if (subscriber) {
			setState((state) => { return { ...state, subscriberObjects: subscriber } })
		}
		// if (getSubscriberMissedSchedules) {
		// 	setState((state) => { return { ...state, } })
		// }
		/* Setting the state of the paymentTracks to the paymentTracks from the redux store. */
		if (paymentTracks) {
			setState((state) => { return { ...state, paymentTracks } })
		}
		if (paymentSchedulesMissed) {
			setState((state) => { return { ...state, missedSchedules: paymentSchedulesMissed } })
		}
		if (paymentSchedules) {
			setState((state) => { return { ...state, paymentSchedulesObjects: paymentSchedules } })
		}
		if (missedPaymentCount) {
			setState((state) => ({ ...state, missedPaymentCount }))
		}
		if (subsPayments) {
			setState((state) => { return { ...state, subsExternalPayments: subsPayments.content } })
		}
		if (missedPaymentSchedulesCount) {
			setState((state) => { return { ...state, missedSchedulesCount: missedPaymentSchedulesCount } })
		}
		if (paymentTracksCount) {
			setState((state) => { return { ...state, count: paymentTracksCount } })
		}
		if (subscriberRevenue) {
			setState((state) => { return { ...state, revenueObject: subscriberRevenue } })
		}
		if (subscriptionSummary) {
			setState((state) => { return { ...state, subscriptionSummary: subscriptionSummary } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defMembership, subscriber, paymentSchedules, subsPayments,
		state.subsExternalPayments, missedPaymentSchedulesCount, paymentTracksCount, missedPaymentCount, subscriberRevenue, subscriptionSummary]);


	useEffect(() => {
		if (!cancellingSubscription && state.key && state.cancelled) {
			setConfirmCancel(false)
			setState((state) => { return { ...state, cancelled: false, key: false, response: cancelledSubscription, showToast: true } })
			cancelSuccess()
		}
		if (!pausingSubscription && pausedSubscription && state.paused) {
			pauseSuccess()
			setConfirmPause(false)
			setState((state) => { return { ...state, paused: false } })
		}
		if (!resumingSubscription && state.key && state.resumed) {
			setConfirmResume(false)
			setState((state) => { return { ...state, resumed: false, key: false } })
			resumeSuccess()
		}
		if (!reschedulingSubscription && state.key && state.rescheduled) {
			setConfirmReschedule(false)
			setState((state) => { return { ...state, rescheduled: false, key: false } })
			rescheduleSuccess()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cancellingSubscription, state.cancelled, state.key,
		pausingSubscription, pausedSubscription, state.paused, resumingSubscription, resumedSubscription, state.resumed,
		reschedulingSubscription, state.rescheduled, rescheduledSubscription
	])

	const loading = fetchingSchedule || fetchingTracks || fetchingSubscriber

	// toast for when a subscription is cancelled successfully
	const cancelSuccess = () => {
		cancelToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.cancelled'), life: 3000 })
	}

	// toast for when a subscription is paused successfully
	const pauseSuccess = () => {
		pauseToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.paused'), life: 3000 })
	}

	// toast for when a subscription is resumed successfully
	const resumeSuccess = () => {
		resumeToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.resumed'), life: 3000 })
	}

	// toast for when a subscription is rescheduled successfully
	const rescheduleSuccess = () => {
		rescheduleToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.rescheduled'), life: 3000 })
	}

	const newDate = moment(date).format('YYYY-MM-DD')

	const footerContent = (
		<div className="subs-actions-footer">
			<Button label={t('subscriber.cancel')} icon="pi pi-times" className="p-button-text" id="resume_no_btn"
				onClick={() => { setConfirmResume(false) }}
			/>
			<Button label={t('subscriber.resume')} icon="pi pi-check" loading={resumingSubscription} disabled={!date} id="resumeSubs_yes_btn"
				onClick={() => {
					resumeSubscriptions(id, newDate, defMembership.id, "RESUME")
					setState((state) => { return { ...state, resumed: true, key: true } })
				}}
				autoFocus />
		</div>
	);

	const pauseSubscriptionActions = (
		<div className="subs-actions-footer">
			<Button label={t('subscriber.cancel')} icon="pi pi-times" id="pause_no_btn"
				onClick={() => { setConfirmPause(false) }}
			/>
			<Button label={t('subscriber.pause')} icon="pi pi-check" loading={pausingSubscription} id="pause_yes_btn"
				onClick={() => {
					pauseSubscriptions(id, defMembership.id);
					setState((state) => { return { ...state, paused: true } })
				}}
				autoFocus />
		</div>
	);

	const cancelSubscriptionActions = (
		<div className="subs-actions-footer">
			<Button label={t('common.no')} icon="pi pi-times" className="p-button-text" id="cancelSubs_no_btn"
				onClick={() => { setConfirmCancel(false) }}
			/>
			<Button label={t('common.yes')} icon="pi pi-check" loading={cancellingSubscription} id="cancelSubs_yes_btn"
				onClick={() => {
					cancelSubscriptions(id, defMembership.id);
					setState((state) => { return { ...state, cancelled: true, key: true } })
				}}
				autoFocus />
		</div>
	);

	const rescheduleSubscriptionActions = (
		<div className="subs-actions-footer">
			<Button label={t('subscriber.cancel')} icon="pi pi-times" className="p-button-text" id="reschedule_no_btn"
				onClick={() => { setConfirmReschedule(false) }}
			/>
			<Button label={t('subscriber.reschedule')} icon="pi pi-check" loading={reschedulingSubscription} disabled={!date} id="reschedule_confirm_btn"
				onClick={() => {
					rescheduleSubscriptions(id, newDate, defMembership.id);
					setState((state) => { return { ...state, rescheduled: true, key: true } })
				}}
				autoFocus />
		</div>
	);

	const calendarDateFormat = defMembership?.business?.country?.jsShortDateFormat;

	return (
		<>
			{loading ? <Loading /> :
				<div>
					<Toast ref={cancelToast} onRemove={(message) => {
						if (message.severity === 'success') {
							getSubscriber(id)
						}
					}} />
					<Toast ref={resumeToast} onRemove={(message) => {
						if (message.severity === 'success') {
							getSubscriber(id)
						}
					}} />
					<Toast ref={pauseToast} onRemove={(message) => {
						if (message.severity === 'success') {
							getSubscriber(id)
						}
					}} />
					< Toast ref={rescheduleToast}
						onRemove={(message) => {
							if (message.severity === 'success') {
								getSubscriber(id)
								getAllPaymentSchedules(id);
							}
						}} />
					<DetailCard subscribers={state.subscriberObjects} totalReceivedPayments={state.revenueObject.collected} totalMissedPayments={missedPaymentSchedulesCount}
						defMembership={defMembership} setState={setState} setConfirmPause={setConfirmPause} setConfirmCancel={setConfirmCancel}
						setConfirmResume={setConfirmResume} setConfirmReschedule={setConfirmReschedule} totalDeductions={state.count} subscriptionSummary={subscriptionSummary}
					/>
					<Dialog
						className="tier_dialog" id="tier_dialog"
						visible={confirmPause}
						style={{ width: "auto" }}
						onHide={() => setConfirmPause(false)}
						header={t("subscriber.confirmation_header")}
						footer={pauseSubscriptionActions}
					>
						{<h6>{t("subscriber.pause_confirmation_message")}</h6>}
					</Dialog>
					<Dialog
						className="tier_dialog" id="tier_dialog"
						visible={confirmCancel}
						style={{ width: "auto" }}
						onHide={() => setConfirmCancel(false)}
						header={t("subscriber.confirmation_header")}
						footer={cancelSubscriptionActions}
					>
						{<h6>{t("subscriber.cancel_confirmation_message")}</h6>}
					</Dialog>
					<Dialog className="tier_dialog" id="tier_dialog" onHide={() => setConfirmResume(false)} visible={confirmResume} header={t("subscriber.resume_label")} footer={footerContent}>
						{
							<div className="field  reschedule-date" >
								<Calendar
									id="startDate"
									name="startDate"
									value={date}
									minDate={today}
									dateFormat={calendarDateFormat}
									readOnlyInput
									onChange={(e) => setDate(e.value)} showIcon
									placeholder={t("subscriber.resumption_date")}
									className="p-datepicker-header"
								/>
							</div>
						}
					</Dialog>
					<Dialog className="tier_dialog" id="tier_dialog" onHide={() => setConfirmReschedule(false)} visible={confirmReschedule} header={t("subscriber.reschedule_label")} footer={rescheduleSubscriptionActions} style={{ width: "23rem" }}>
						{
							<div className="field  reschedule-date">
								<Calendar
									id="startDate"
									name="startDate"
									value={date}
									minDate={today}
									readOnlyInput
									onChange={(e) => setDate(e.value)} showIcon
									placeholder={t("subscriber.rescheduling_date")}
									className="p-datepicker-header"
								/>
							</div>
						}
					</Dialog>

					{
						loading ? <Loading /> :
							<TransactionsTabs payments={state.paymentTracks} missed={state.missedSchedules} missedSchedulesCount={state.missedSchedulesCount} collectedPaymentsCount={state.count} />
					}
				</div>
			}
		</>
	);
}
export default Subscribers;
