import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import { FullWidthCardTemplate, SmallCardTemplate } from '../../../Products/ProductSummaryInsights';
import "../../../../Styles/pages/subscriptionDetail.scss"

const DetailCard = (props) => {
    const { subscribers, subscriptionSummary } = props
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const { t } = useTranslation("common");
    const newDate = moment(props.subscribers?.nextBillingDate).format("LLL")
    const pausedDate = moment(props.subscribers?.pausedOn).format("LLL")
    const cancelledDate = moment(props.subscribers?.cancelledOn).format("LLL")
    const completedDate = moment(props.subscribers?.completedOn).format("LLL")
    const currency = props.defMembership?.business.country.currency
    const amount = (props.subscriber?.plan?.flex !== false && props.subscribers?.cost === null) ? (t("subscriber.flex_true"))
        : (currency + " " + Number.parseFloat(props.subscribers?.cost).toFixed(2))

    const subscriptionCreatedOnDate = moment(subscribers.createdOn).format("LL")
    const subscriptionLastModifiedOnDate = moment(subscribers.lastModifiedDate).format("LL")

    const moneyTemplate = (amount = 0, className1 = '', className2 = '') => {
        const formattedAmount = amount ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(amount?.toFixed(2))) : "0.00";
        return (
            <div className='flex'>
                <p className={className1}>{defMembership.business.currency}</p>
                <p className={className2}> {formattedAmount}</p>
            </div>
        )
    }


    const mainCardPlan = [
        {
            page: "subscription-detail",
            subscribers,
            t,
            defMembership,
            subscriptionCreatedOnDate,
            subscriptionLastModifiedOnDate,
        }
    ]

    const smallCardData = [
        {
            title: t("business_customers.total-col-payments"),
            subDesc: t("business_customers.payments"),
            value: <strong className='amt-fig' style={{ color: "#66BB6A" }}>{subscriptionSummary?.collectedCount}</strong>,
            cardInfo: t("subscriber.tooltip", { status: t("Completed"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        },
        {
            title: t("subscriber.total_collected"),
            value: moneyTemplate(subscriptionSummary?.collectedAmount, 'isCollectedCurren', 'isCollected'),
            cardInfo: t("subscriber.tooltip", { status: t("Collected"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        },
        {
            title: t("subscriber.total_revenue"),
            value: moneyTemplate(subscriptionSummary?.redeemable, 'isRevenueCurren', 'isRevenue'),
            cardInfo: t("subscriber.tooltip", { status: t("Revenue"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        },
        {
            title: t("business_customers.total-mis-payments"),
            subDesc: t("business_customers.payments"),
            value: <strong className='amt-fig' style={{ color: '#D0021B' }}>{subscriptionSummary?.missedCount}</strong>,
            cardInfo: t("subscriber.tooltip", { status: t("Missed"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        }
    ]

    return (
        <div className="grid detail_card" style={{ marginBottom: "20px" }}>
            <div className='grid col-9'>
                <FullWidthCardTemplate summary={mainCardPlan} />

                <div className='col-12 mt-0'>
                    <div className='notice-alert'>
                        <div className='flex align-items-center'>
                            <i className='pi pi-info-circle mr-1' style={{color:"#23547B"}}/>
                            <p className='notice-label'>{t("subscriber.notice")}</p>
                        </div>
                        <br />
                        <p className='payment-added-alert2'>
                            {subscribers.status === "CANCELLED" ? <Trans i18nKey={t("subscriber.modified-date")}
                                values={{ status: t("Cancelled"), date: cancelledDate }}
                                components={{ 1: <strong style={{ color: "#d0021b", fontFamily: "RobotoCondensed" }} />, 2: <span style={{ color: "#23547B " }} /> }} />
                                : subscribers.status === "PAUSED" ? <Trans i18nKey={t("subscriber.modified-date")}
                                    values={{ status: t("Paused"), date: pausedDate }}
                                    components={{ 1: <strong style={{ color: "#805B36", fontFamily: "RobotoCondensed" }} />, 2: <span style={{ color: "#23547B " }} /> }} />
                                    :
                                    subscribers.status === "COMPLETE" ? <Trans i18nKey={t("subscriber.modified-date")}
                                        values={{ status: t("Completed"), date: completedDate }}
                                        components={{ 1: <strong style={{ color: "#666678", fontFamily: "RobotoCondensed" }} />, 2: <span style={{ color: "#23547B " }} /> }} />
                                        :
                                        <Trans i18nKey={t("subscriber.due_active")}
                                            values={{ value1: amount, value2: newDate }}
                                            components={{ 1: <strong style={{ color: "#256029", fontFamily: "RobotoCondensed" }} />, 3: <span style={{color:"#23547B"}} />,2: <strong style={{ color: "#23547B " }}  /> }} />
                            }
                        </p>
                    </div>
                </div>

                <h6 className="title-tile ml-1 mb-1 mt-1">{t("products.summary")}</h6>
                <div className='grid col-12'>
                    <SmallCardTemplate summary={smallCardData} />
                </div>
            </div>
            <div className='col-3'>
                {defMembership.role !== "VIEWER" && <div id="side-menu">
                    {subscribers?.status === "PAUSED" ?
                        <div className="col-12">
                            <span onClick={() => {
                                props.setConfirmResume(true)
                                props.setState((state) => { return { ...state, resumed: true } })
                            }}>
                                <i className="pi pi-refresh" >
                                </i>{t('subscriber.resume_label')}
                            </span>
                            <hr />
                        </div> : ""}

                    {
                        subscribers?.status === "ACTIVE" &&
                        <div className="col-12">
                            <span onClick={() => {
                                props.setConfirmReschedule(true)
                            }}
                            >
                                <i className="pi pi-calendar" >
                                </i>{t('subscriber.reschedule_label')}
                            </span>
                            <hr />
                        </div>
                    }

                    {subscribers?.status === "ACTIVE" ?
                        <div className="col-12">
                            <span onClick={() => {
                                props.setConfirmPause(true)
                            }}
                            >
                                <i className="pi pi-pause" >
                                </i>{t('subscriber.pause_label')}
                            </span>
                            <hr />
                        </div> : ""}

                    {subscribers?.status !== "CANCELLED" && subscribers?.status !== "EXPIRED" && subscribers?.status !== "COMPLETE" &&
                        <div className="col-12" >
                            < span onClick={() => {
                                props.setConfirmCancel(true)
                                props.setState((state) => { return { ...state, cancelled: true } })
                            }}>
                                <i className="pi pi-stop-circle" ></i>
                                {t('subscriber.cancel_label')}
                            </span>
                        </div>}
                    <hr />
                </div>}
            </div>

        </div >
    )
}
export default DetailCard