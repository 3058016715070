import React from 'react';
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import TelecelCashLogo from "../../assets/images/Telecel-Cash-Logo.jpg";
import ATMoneyLogo from "../../assets/images/airteltigo-money-logo.png";
import MTN_MomoLogo from "../../assets/images/momo.jpg";
import Mastercard from "../../assets/images/mastercard.png";
import Visacard from "../../assets/images/visacard.png";
import { Link } from 'react-router-dom';

function PaymentProviders() {

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const verifStatus = useSelector((state) => state.documents.verifStatus);

    const { t } = useTranslation("common");

    const pmtProviderArray = [
        {
            id: 1,
            reference: 'APPSNMOBILE',
            name: t("api_settings.appsNmobile_ltd_txt"),
            description: t("api_settings.OrchardPay_txt"),
            navigation: "/payment_providers/appsNmobile"
        },
        {
            id: 2,
            reference: 'PAYSWITCH',
            name: t("login.payswitch_txt"),
            preferredTag: t("api_settings.preferred"),
            navigation: "/payment_providers/paySwitch"
        }
    ];

    return (
        <div>
            <Card className="provider_list">
                <div className="provider_list_header_div">
                    <p className="provider_list_header">{t("api_settings.provider_list_header")}</p>
                    <p className="provider_list_txt">{t("api_settings.provider_list_txt")}</p>
                </div>

                <div className="main_provider_items">
                    {pmtProviderArray.map((item) => (
                        <Link
                            to={`${item.navigation}`}
                            key={item.id}>
                            <div className="grid" style={{ margin: "1rem" }}>
                                <div className="col-3" id="left_main_provider_items">
                                    <div className="left_div_provider_items">
                                        {item.reference === "PAYSWITCH" && < div className="preferred_div">
                                            <p id="preferred">{t("api_settings.preferred")}</p>
                                        </div>}
                                        <div>
                                            <p className="appsNmobile_ltd_txt">{item.name}</p>
                                            <p className="orchardPay_txt_list">{item.description}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3 payment_methods_logos">
                                    <img src={Mastercard} alt="MasterCard" />
                                    <img src={Visacard} alt="VisaCard" />
                                    <img src={TelecelCashLogo} alt="TCashLogo" />
                                    <img src={MTN_MomoLogo} alt="MTNMomo" />
                                    <img src={ATMoneyLogo} alt="ATMoney" />
                                </div>

                                <div className="col-6 right_side_labels">
                                    {((!defMembership.business.verified && verifStatus === null) ||
                                        (!defMembership.business.verified && defMembership.business.pmtProvider !== item.reference && verifStatus > 0)) &&
                                        <span id="not_activated_label">{t("api_settings.not_activated_label")}</span>}

                                    {(defMembership.business.verified && defMembership.business.pmtProvider !== item.reference) &&
                                        <span id="not_activated_label">{t("api_settings.not_activated_label")}</span>}

                                    {item.reference === defMembership.business.pmtProvider && defMembership.business.verified
                                        && <span className="active_label">{t("api_settings.active_label")}</span>}

                                    {((!defMembership.business.verified) && verifStatus > 0 && defMembership.business.pmtProvider === item.reference)
                                        && <span className="in_progress_label">{t("api_settings.in_progress_label")} </span>}
                                    <i className='pi pi-chevron-right payments_chevron_right'></i>
                                </div>
                            </div>
                            {item.id === 1 && <hr className="hr_providers" />}
                        </Link>
                    ))}
                </div>
            </Card >
        </div >
    )
}

export default PaymentProviders;
