import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
    beginOnboardingRequest: ["businessId","paymentProvider"],
    beginOnboardingRequestSuccess: ["onboardingRequestStarted"],
    beginOnboardingRequestFailure: ["error"]
})

export const BeginOnboardingRequestTypes = Types;

export default Creators;

export const INITIAL_STATE = Immutable({
    onboardingRequestInProgress: null,
    onboardingRequestStarted: null,
    errorOnboardingRequest: null
})

export const beginOnboardingRequest = (state) => {
    return { ...state, onboardingRequestInProgress: true, onboardingRequestStarted: null }
}

export const beginOnboardingRequestSuccess = (state, action) => {
    const { onboardingRequestStarted } = action
    return { ...state, onboardingRequestInProgress: false, errorOnboardingRequest: null, onboardingRequestStarted }

}

export const beginOnboardingRequestFailure = (state, action) => {
    const { error } = action
    return { ...state, errorOnboardingRequest: error, onboardingRequestStarted: null, onboardingRequestInProgress: false }
}

export const reducer = createReducer(INITIAL_STATE, {
    [Types.BEGIN_ONBOARDING_REQUEST]: beginOnboardingRequest,
    [Types.BEGIN_ONBOARDING_REQUEST_SUCCESS]: beginOnboardingRequestSuccess,
    [Types.BEGIN_ONBOARDING_REQUEST_FAILURE]: beginOnboardingRequestFailure,
})